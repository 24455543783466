import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LikeManagerService {
  like = false;

  constructor() { }

  showLike() {
    this.like = true;
    setTimeout(() => {
      this.like = false;
    }, 3100);
  }
}
