import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import html2canvas from 'html2canvas';
import { LevelManagerService } from 'src/app/services/level-manager.service';
import { LikeManagerService } from 'src/app/services/like-manager.service';
import { ProblemGeneratorService } from 'src/app/services/problem-generator.service';
import { ResultService } from 'src/app/services/result.service';
import { ErrorResultadoComponent } from '../error-resultado/error-resultado.component';
import { ErrorComponent } from '../error/error.component';
import { InstruccionComponent } from '../instruccion/instruccion.component';
import { QuestionComponent } from '../question/question.component';
import { ErrorResultado2Component } from '../error-resultado2/error-resultado2.component';
import { Error2Component } from '../error2/error2.component';

@Component({
  selector: 'app-tabla-selector',
  templateUrl: './tabla-selector.component.html',
  styleUrls: ['./tabla-selector.component.scss']
})
export class TablaSelectorComponent implements OnInit {

  @Input() mostrarTabla = true;
  @Input() nivel = 1;
  resultados: any[] = [];
  already: number[] = [];
  numberText: string[] = ['Diez', 'Una', 'Dos', 'Tres', 'Cuatro', 'Cinco', 'Seis', 'Siete', 'Ocho', 'Nueve', 'Diez'];
  // numberText: string[] = ['cero', 'una', 'dos', 'tres', 'cuatro', 'cinco', 'seis', 'siete', 'ocho', 'nueve', 'diez'];

  stepsReview: any[] = [''];
  stepsColsReview: any[] = [''];
  stepsRowsReview: any[] = [''];
  finalReview: string = '';

  config: any[] = [''];
  steps: any[] = [''];
  stepsRows: any[] = [''];
  stepsCols: any[] = [''];
  final: string = '';
  @Input() problema: any = {};
  @Input() entrenamiento = true;

  step: number = 0;
  noInvertido: boolean = false;
  get invertido() { return !this.noInvertido }

  working: boolean = false;
  stepsResults: boolean[] = new Array(20).fill(true);
  hover: number = 0;

  tablaInteractiva: boolean = true;
  fullscreen: boolean = false;

  isMobile = () => {
    let response = false;
    response = response || /IEMobile/i.test(navigator.userAgent);
    response = response || /Android/i.test(navigator.userAgent);
    response = response || /BlackBerry/i.test(navigator.userAgent);
    response = response || /iPhone|iPad|iPod/i.test(navigator.userAgent);
    return response
  };

  constructor(
    private generator: ProblemGeneratorService,
    private modalService: NgbModal,
    public result: ResultService,
    public likeManager: LikeManagerService,
    public activeModal: NgbActiveModal,
    private levelManager: LevelManagerService
  ) { }

  ngOnInit(): void {
    this.restart();
  }

  setTabla(tipo: number) {
    this.problema = this.generator.generarNivelBase(tipo, 9);
    this.mostrarTabla = true;
    this.restart();
  }

  toggleFullscreen() {
    let elements = document.getElementsByClassName('modal-ayuda');
    if (elements.length > 0) {
      elements[0].classList.toggle('modal-fullscreen');
    }
  }

  toggleTabla(estado: boolean) {
    this.tablaInteractiva = estado;
    if (!this.tablaInteractiva) this.nivel = 2;
    if (this.tablaInteractiva) this.nivel = 1;
  }

  timeout() {
    setTimeout(() => {
      this.timeout();
    }, 1000);
  }

  ceil(number: number) {
    return Math.ceil(number);
  }

  deshacer() {
    this.steps.pop();
    this.steps.pop();
    this.steps.push('');
    this.config.pop();
    this.config.pop();
    this.config.push('');
    this.step--;
    this.stepsResults[this.step] = true;
    this.resultados.pop();
  }

  select(num: number, row: number = 0, cell: number = 0) {
    if (this.step < this.problema.steps.length) {
      this.working = true;
      if (!this.stepsResults[this.step]) return this.working = false;
      this.stepsResults[this.step] = false;
      if (this.step >= this.problema.steps.length) {
        this.checkFinalAnswer();
        return this.working = false;
      };
      // Comprobar corrección en cada paso
      this.checkStep(num, row, cell);
      return this.working = false;
    }
    return this.working = false;
  }

  capturar() {
    return new Promise((resolve, reject) => {
      let imagenes = ['', ''];
      let final = '' + this.final;
      let steps = this.steps.slice(0);
      let stepsRows = this.stepsRows.slice(0);
      let stepsCols = this.stepsCols.slice(0);
      this.finalReview = final;
      this.stepsReview = steps;
      this.stepsColsReview = stepsCols;
      this.stepsRowsReview = stepsRows;
      setTimeout(() => {
        html2canvas(<any>document.querySelector("#grid")).then(canvas => {
          const img = canvas.toDataURL('image/png')
          imagenes[0] = img;
          this.finalReview = '' + this.problema.resultado;
          this.stepsReview = this.problema.steps.slice(0);
          this.stepsColsReview = this.problema.stepsCols.slice(0);
          this.stepsRowsReview = this.problema.stepsRows.slice(0);
          setTimeout(() => {
            html2canvas(<any>document.querySelector("#grid")).then(canvas => {
              const img = canvas.toDataURL('image/png')
              imagenes[1] = img;
              resolve(imagenes)
            });
          }, 1);
        }).catch((err) => {
          resolve([])
        });
      }, 1);
    })
  }

  markMove(e: any, row: number, cell: number) {
    if (this.step == this.problema.valor2) return;
    var xPos = e.targetTouches[0].pageX - window.scrollX;
    var yPos = e.targetTouches[0].pageY - window.scrollY;

    let element = <any>document.elementFromPoint(xPos, yPos)
    if (!element.attributes || !element.attributes.row || !element.attributes.cell) {
      this.unmarkHover(row, cell);
      return;
    }
    let elements = <any>document.getElementsByClassName('hovered');
    for (let index = 0; index < elements.length; index++) {
      const element = elements[index];
      element.classList.remove('hovered');
    }
    if (element.classList.contains('cell-btn')) element = element.parentNode;
    element.classList.add('hovered');
    this.hover = (element.attributes.row.value - 0) * 10 + (element.attributes.cell.value - 0);
  }

  markHover(row: number, cell: number) {
    if (this.step == this.problema.valor2) return;
    this.hover = row * 10 + cell;
  }

  finishHover(e: any, row: number, cell: number) {
    if (this.step == this.problema.valor2) return;
    this.hover = 0;
    let elements = <any>document.getElementsByClassName('hovered');
    for (let index = 0; index < elements.length; index++) {
      const element = elements[index];
      element.classList.remove('hovered');
    }

    var xPos = e.changedTouches[0].pageX - window.scrollX;
    var yPos = e.changedTouches[0].pageY - window.scrollY;

    let element = <any>document.elementFromPoint(xPos, yPos)
    if (!element.attributes || !element.attributes.row || !element.attributes.cell) {
      return;
    }
    row = element.attributes.row.value - 0;
    cell = element.attributes.cell.value - 0;

    this.select((row * 10) + cell, row, cell)
  }

  unmarkHover(row: number, cell: number) {
    this.hover = 0;
    let elements = <any>document.getElementsByClassName('hovered');
    for (let index = 0; index < elements.length; index++) {
      const element = elements[index];
      element.classList.remove('hovered');
    }
  }

  getClass(row: number, cell: number) {
    for (let index = 0; index < this.steps.length - 1; index++) {
      if (index == this.steps.length - 2) {
        if (!this.hover) {
          const step = this.steps[index];
          const stepRow = this.stepsRows[index];
          const stepCell = this.stepsCols[index];
          if (!this.invertido && row <= stepRow && (stepCell >= cell)) return 'hover clicked-' + (row % 2);
          if (this.invertido && row <= stepCell - 1 && (stepRow + 1 >= cell)) return 'hover clicked-' + (row % 2);
        }
      }
    }
    if (row <= ((this.hover - 1) / 10) && (this.hover % 10 >= cell || (this.hover % 10 == 0 && this.hover != 0))) return 'hover clicked-' + (row % 2);
    // if (row * 10 + cell < this.hover) return 'hover clicked-' + ((this.steps.length - 1) % 2);
    return '';
  }

  getClassReview(row: number, cell: number) {
    for (let index = 0; index < this.steps.length - 1; index++) {
      if (index == this.steps.length - 2) {
        if (!this.hover) {
          const step = this.steps[index];
          const stepRow = this.stepsRowsReview[index];
          const stepCell = this.stepsColsReview[index];
          if (!this.invertido && row <= stepRow && (stepCell >= cell)) return 'hover clicked-' + (row % 2);
          if (this.invertido && row <= stepCell - 1 && (stepRow + 1 >= cell)) return 'hover clicked-' + (row % 2);
        }
      }
    }
    return '';
  }

  mostrarInformacion() {
    let nivel = this.nivel % 2 == 0 ? 2 : 1;
    let step = this.nivel % 2 == 0 && this.step == this.problema.steps.length ? 1 : this.nivel % 2 == 0 ? 0 : this.step == 0 ? 0 : this.step == this.problema.steps.length ? 2 : 1;

    let x = (this.invertido) ? this.problema.valor2 + '' : this.problema.valor1 + '';
    let y = (this.invertido) ? this.problema.valor1 + '' : this.problema.valor2 + '';
    let z = (this.invertido) ? this.problema.valor1 + '' : this.step + 1 + '';
    let x2 = (this.invertido) ? this.step + 1 + '' : this.problema.valor1 + '';

    let w = `${this.numberText[this.invertido ? this.problema.valor1 : this.steps.length]} ${(this.invertido ? this.problema.valor1 : this.steps.length) == 1 ? 'vez' : 'veces'} ${(this.invertido ? this.steps.length : this.problema.valor1) == 1 ? 'uno' : this.numberText[this.invertido ? this.steps.length : this.problema.valor1]}`
    let w2 = `${this.numberText[this.invertido ? this.problema.valor1 : this.problema.valor2]} ${(this.invertido ? this.problema.valor1 : this.problema.valor2) == 1 ? 'vez' : 'veces'} ${(this.invertido ? this.problema.valor2 : this.problema.valor1) == 1 ? 'uno' : this.numberText[this.invertido ? this.problema.valor2 : this.problema.valor1]}`

    let r = this.steps[this.step - 1];

    let timeout = false;

    setTimeout(() => {
      timeout = true;
    }, 300);

    const modalRef = this.modalService.open(InstruccionComponent, {
      size: 'lg', centered: !this.isMobile(), backdropClass: 'white',
      backdrop: (this.generator.instrucciones2[nivel][step].split('${split}').length > 1 || this.step == this.problema.steps.length ? 'static' : true ),
      beforeDismiss: () => {
        return timeout;
      }
    });
    modalRef.componentInstance.instruccion = this.generator.instrucciones2[nivel][step].replace('${Z}', z).replace('${X}', x).replace('${Y}', y).replace('${X}', x).replace('${Y}', y).replace('${Z}', z).replace('${W}', w).replace('${W}', w).replace('${W2}', w2).replace('${W2}', w2).replace('${X2}', x2).replace('${X2}', x2).replace('${R}', r);
    modalRef.componentInstance.btnListo = this.step == this.problema.steps.length;
    modalRef.result.then((result) => {
      if (result) {
        this.activeModal.close();
      }
    }, (reason) => { });
    // }
  }

  toggleEntrenamiento() {
    if (!this.result.haveUserId()) return;
    let msg = "¿Estás seguro de que quieres terminar el entrenamiento?";
    if (!this.entrenamiento) {
      msg = "<p>Cambiar a modo de entrenamiento hará que se pierda el progreso actual.</p><p>¿desea continuar?</p>"
    }
    const modalRef = this.modalService.open(QuestionComponent, { size: 'lg', scrollable: true, centered: !this.isMobile() });
    modalRef.componentInstance.mensaje = msg;
    modalRef.result.then((result) => {
      this.entrenamiento = !this.entrenamiento;
      this.result.cleanResults();
      this.restart();
    }, (reason) => { });
  }

  isReady(): boolean {
    if (!this.problema.steps) this.problema.steps = [];
    return true;
  }

  async checkFinalAnswer() {
    if (this.step != this.problema.steps.length) {
      this.checkStep(this.steps[this.step]);
      return;
    }
    this.final = this.steps[this.step - 1];

    this.working = true;
    if (!this.stepsResults[this.step]) {
      this.working = false;
      return;
    }
    this.stepsResults[this.step] = false;
    let stringFinal = this.final.toString();
    this.activeModal.close({ reserva: stringFinal.length > 1 ? this.final.toString()[0] : 0, resultado: this.final.toString().length > 1 ? this.final.toString()[1] : this.final.toString()[0] });
    this.working = false;
    return;
  }

  checkStep(value: number, row: number = -1, cell: number = -1) {
    let result = true;
    if (row == -1 && cell == -1) result = value == this.problema.steps[this.step];
    else {
      value = ((row + 1) * cell);
      result = value == this.problema.steps[this.step] && ((!this.invertido && (this.step == row) && (this.problema.valor1 == cell)) || (this.invertido && (this.problema.valor1 == row + 1) && (this.step == cell - 1)));
    }
    // Siguiente paso
    this.step++;
    this.steps[this.step - 1] = value;
    this.stepsRows[this.step - 1] = (this.invertido) ? cell - 1 : row;
    this.stepsCols[this.step - 1] = (this.invertido) ? row + 1 : cell;
    this.steps.push('');
    this.config.push('');

    if (!result && this.entrenamiento) {
      if (this.nivel % 2 == 0) {
        this.stepsResults[this.step] = true;
        const modalRef = this.modalService.open(ErrorResultado2Component, { size: 'xl', scrollable: true });
        let problema = Object.assign({}, this.problema);
        problema.valor2 = this.step;
        modalRef.componentInstance.problema = problema;
        modalRef.componentInstance.invertido = this.invertido;
        modalRef.componentInstance.miResultado = this.steps[this.step - 1];
        modalRef.componentInstance.resultado = this.problema.steps[this.step - 1];
        modalRef.result.then((result) => {
          this.deshacer();
        }, (reason) => {
          this.deshacer();
        });
      } else {
        const modalRef = this.modalService.open(Error2Component, { size: 'xl', scrollable: true, backdrop: 'static' });
        modalRef.componentInstance.problema = this.problema;
        modalRef.componentInstance.misSteps = this.steps;
        modalRef.componentInstance.misStepsRows = this.stepsRows;
        modalRef.componentInstance.misStepsCols = this.stepsCols;

        modalRef.componentInstance.config = this.config;
        modalRef.componentInstance.invertido = this.invertido;
        modalRef.componentInstance.step = this.step;
        modalRef.componentInstance.steps = this.problema.steps.slice(0, this.step);
        modalRef.componentInstance.stepsCols = this.problema.stepsCols;
        modalRef.componentInstance.stepsRows = this.problema.stepsRows;
        modalRef.result.then((result) => {
          this.deshacer();
        }, (reason) => {
          this.deshacer();
        });
      }
      // this.step--;
      // this.stepsResults[this.step] = true;
    } else {
      // Guardar resultado
      this.resultados.push({
        esperado: '' + this.problema.steps[this.step - 1],
        registrado: '' + this.steps[this.step - 1],
        row: row,
        col: cell,
        rowEsperada: this.invertido ? this.problema.stepsCols[this.step - 1] - 1 : this.problema.stepsRows[this.step - 1],
        colEsperada: this.invertido ? this.problema.stepsRows[this.step - 1] + 1 : this.problema.stepsCols[this.step - 1]
      })

      if (this.entrenamiento) {
        this.likeManager.showLike();
        this.mostrarInformacion();
      }
    }
  }

  restart() {
    if (this.entrenamiento) this.already = [];
    this.step = 0;
    this.already.push(this.problema.valor2)
    this.steps = [''];
    this.stepsCols = [''];
    this.stepsRows = [''];
    this.config = [''];
    this.final = '';
    this.resultados = [];
    this.stepsResults = new Array(20).fill(true);
    if (this.entrenamiento && this.mostrarTabla) {
      this.mostrarInformacion();
    }
  }

}
