<div class="modal-content">
    <div class="modal-header">
        <h4 class="modal-title">Ventana de Correción</h4>
        <button type="button" class="btn-close btn-close-white" aria-label="Close"
            (click)="activeModal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body bg-white">
        <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
            <li [ngbNavItem]="1">
                <a ngbNavLink>Respuesta Correcta</a>
                <ng-template ngbNavContent>
                    <div class="work-area" style="transform: scale(1);">
                        <div class="problema d-flex justify-content-center align-items-center">
                            <div class="num1">{{invertido ? problema.valor1 : problema.valor2}}</div>
                            <i><img style="height: 30px; width: 30px;" src="./assets/templates/dot.svg" alt=""></i>
                            <div class="num2">{{invertido ? problema.valor2 : problema.valor1}}</div>
                            <div class="mx-2"> = </div>
                            <input type="text" autocomplete="off" mask="separator.0" thousandSeparator="."
                                [(ngModel)]="resultado" readonly>
                        </div>
                    </div>
                </ng-template>
            </li>
            <li [ngbNavItem]="2">
                <a ngbNavLink>Tu Respuesta</a>
                <ng-template ngbNavContent>
                    <div class="work-area">
                        <div class="problema d-flex justify-content-center align-items-center">
                            <div class="num1">{{invertido ? problema.valor1 : problema.valor2}}</div>
                            <i><img style="height: 30px; width: 30px;" src="./assets/templates/dot.svg" alt=""></i>
                            <div class="num2">{{invertido ? problema.valor2 : problema.valor1}}</div>
                            <div class="mx-2"> = </div>
                            <input type="text" autocomplete="off" mask="separator.0" thousandSeparator="."
                                [(ngModel)]="miResultado" readonly>
                        </div>
                    </div>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="p-3"></div>
    </div>
    <div class="modal-footer btns bg-white">
        <!-- <button type="button" class="btn btn-success" (click)="guardar()">Volver a intentar</button> -->
        <button type="button" class="btn btn-listo" (click)="activeModal.dismiss('Cancel click')">Revisado</button>
    </div>
</div>