<div class="bg-white rounded">
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">Ventana de Correción</h4>
            <button type="button" class="btn-close btn-close-white" aria-label="Close"
                (click)="activeModal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
            <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                <li [ngbNavItem]="1">
                    <a ngbNavLink>Respuesta Correcta</a>
                    <ng-template ngbNavContent>
                        <div class="problema d-flex justify-content-center align-items-center">
                            <div class="num1">{{problema.valor1.join('')}}</div>
                            <i><img style="height: 30px; width: 30px;" src="./assets/templates/dot.svg" alt=""></i>
                            <div class="num2">{{problema.valor2.join('')}}</div>
                            <div class="mx-2"> = </div>
                            <input type="text" autocomplete="off" mask="separator.0" thousandSeparator="." [(ngModel)]="resultado" readonly>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="2">
                    <a ngbNavLink>Tu Respuesta</a>
                    <ng-template ngbNavContent>
                        <div class="problema d-flex justify-content-center align-items-center">
                            <div class="num1">{{problema.valor1.join('')}}</div>
                            <i><img style="height: 30px; width: 30px;" src="./assets/templates/dot.svg" alt=""></i>
                            <div class="num2">{{problema.valor2.join('')}}</div>
                            <div class="mx-2"> = </div>
                            <input type="text" autocomplete="off" mask="separator.0" thousandSeparator="." [(ngModel)]="miResultado" readonly>
                        </div>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="nav" class="p-3"></div>
        </div>
        <div class="modal-footer btns">
            <!-- <button type="button" class="btn btn-success" (click)="guardar()">Volver a intentar</button> -->
            <button type="button" class="btn btn-listo" (click)="activeModal.dismiss('Cancel click')">Revisado</button>
        </div>
    </div>
</div>