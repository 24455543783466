import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormatterService } from 'src/app/utils/formatter/formatter.service';

@Component({
  selector: 'app-error2',
  templateUrl: './error2.component.html',
  styleUrls: ['./error2.component.scss']
})
export class Error2Component implements OnInit {
  numberText: string[] = ['Diez', 'Una', 'Dos', 'Tres', 'Cuatro', 'Cinco', 'Seis', 'Siete', 'Ocho', 'Nueve', 'Diez'];

  active = 2;

  @Input() invertido: boolean = false;
  @Input() config: any = {};
  @Input() step: number = 0;
  @Input() problema: any = {};

  @Input() steps: any[] = [];
  @Input() stepsCols: any[] = [];
  @Input() stepsRows: any[] = [];
  @Input() misSteps: any[] = [];
  @Input() misStepsCols: any[] = [];
  @Input() misStepsRows: any[] = [];

  get cols() { return this.invertido ? this.stepsRows.map(e => e + 1) : this.stepsCols }
  get rows() { return this.invertido ? this.stepsCols.map(e => e - 1) : this.stepsRows }

  get misCols() { return this.invertido ? this.misStepsRows.map(e => e + 1) : this.misStepsCols }
  get misRows() { return this.invertido ? this.misStepsCols.map(e => e - 1) : this.misStepsRows }

  constructor(
    public activeModal: NgbActiveModal,
    public formatter: FormatterService
  ) {}

  getClass(row: number, cell: number) {
    let index = this.steps.length - 1;
    // for (let index = 0; index < this.steps.length; index++) {
      const stepRow = this.stepsRows[index];
      const stepCell = this.stepsCols[index];
      if (!this.invertido && row <= stepRow && (stepCell >= cell)) return 'hover clicked-' + (row % 2);
      if (this.invertido && row <= stepCell - 1 && (stepRow + 1 >= cell)) return 'hover clicked-' + (row % 2);
    // }
    return '';
  }

  getMyClass(row: number, cell: number) {
    let index = this.steps.length-1;
    // for (let index = 0; index < this.steps.length; index++) {
      const stepRow = this.misStepsRows[index];
      const stepCell = this.misStepsCols[index];
      if (!this.invertido && row <= stepRow && (stepCell >= cell)) return 'hover clicked-' + (row % 2);
      if (this.invertido && row <= stepCell - 1 && (stepRow + 1 >= cell)) return 'hover clicked-' + (row % 2);
    // }
    return '';
  }

  // getMyClass(row: number, cell: number) {
  //   for (let index = 0; index < this.steps.length; index++) {
  //     const step = this.misSteps[index];
  //     if (row * 10 + cell < step) return 'hover clicked-' + (index % 2);
  //     if (row * 10 + cell == step) return 'clicked clicked-' + (index % 2);
  //   }
  //   return '';
  // }
  
  ngOnInit() {
  }

  ceil(number: number) {
    return Math.ceil(number);
  }
  
  guardar(){
  }

}
