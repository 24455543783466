<div class="contenedor-principal semi h-100 w-100 d-flex flex-column justify-content-center p-5">
    <div class="options w-100 d-flex justify-content-between">
        <div id="tools" class="d-flex flex-column gap-3">
            <button class="btn" (click)="mostrarInformacion()"><img id="btn-info" src="./assets/templates/info.svg" alt="Información"></button>
            <button *ngIf="step > 0 && step < 3" class="btn btn-ayuda" (click)="mostrarAyuda()"><img id="btn-ayuda" src="./assets/templates/tool.svg" alt="Información"></button>
        </div>
        <div class="options2 d-flex gap-3">
            <app-ring [step]="result.results.length" *ngIf="!entrenamiento"></app-ring>
        </div>
    </div>
    <div class="work-container w-100 h-100">
        <div class="work-area w-100 d-flex justify-content-evenly p-3 gap-3">
            <div class="problema d-flex justify-content-center align-items-center">
                <div class="num1">{{problema.valor1.join('')}}</div>
                <i><img class="times-dot-sm" src="./assets/templates/dot.svg" alt=""></i>
                <div class="num2">{{problema.valor2.join('')}}</div>
                <div class="mx-2"> = </div>
                <input type="text" autocomplete="off" mask="separator.0" thousandSeparator="." [(ngModel)]="final"
                    *ngIf="step == problema.steps.length">
                <input type="text" autocomplete="off" mask="separator.0" thousandSeparator="." readonly
                    [(ngModel)]="final" *ngIf="step != problema.steps.length">
            </div>
        </div>

        <div class="work-area w-100 d-flex justify-content-evenly p-3 gap-3" id="grid">
            <div class="problema d-flex justify-content-center align-items-center">
                <div class="num1">{{problema.valor1.join('')}}</div>
                <i><img class="times-dot-sm" src="./assets/templates/dot.svg" alt=""></i>
                <div class="num2">{{problema.valor2.join('')}}</div>
                <div class="mx-2"> = </div>
                <input type="text" autocomplete="off" mask="separator.0" thousandSeparator="." [(ngModel)]="finalFinal" tabindex="-1"
                    *ngIf="step == problema.steps.length">
                <input type="text" autocomplete="off" mask="separator.0" thousandSeparator="." readonly [(ngModel)]="finalFinal" tabindex="-1"
                    *ngIf="step != problema.steps.length">
            </div>
        </div>
    </div>
    <div class="footer btns w-100 d-flex justify-content-between">
        <div class="like slide-up" *ngIf="likeManager.like"><img src="./assets/templates/smile.svg" alt="">
        </div>
        <button class="btn btn-reiniciar mt-auto" tabindex="-1" (click)="restart()">Reiniciar</button>
        <div class="d-flex flex-column justify-content-center align-items-center">
            <div class="title">Entrenamiento</div>
            <button class="btn btn-entrenamiento mt-auto" *ngIf="entrenamiento" (click)="toggleEntrenamiento()"
                tabindex="-1">Activado</button>
            <button class="btn btn-desentrenamiento mt-auto" *ngIf="!entrenamiento" (click)="toggleEntrenamiento()"
                tabindex="-1">Desactivado</button>
        </div>
        <button class="btn btn-listo mt-auto" (click)="listo()" *ngIf="isReady()">Listo</button>
        <button class="btn btn-listo disabled mt-auto" *ngIf="!isReady()">Listo</button>
    </div>
</div>