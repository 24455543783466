<div class="bg-white rounded">
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title"></h4>
            <button type="button" class="btn-close btn-close-white" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body d-flex flex-column justify-content-center align-items-center" style="min-height: 30vh; text-align: center;" [innerHtml]="mensaje">
            
        </div>
        <div class="modal-footer d-flex justify-content-between align-items-between btns">
            <button type="button" class="btn btn-listo" (click)="activeModal.dismiss('Cancel click')">No</button>
            <button type="button" class="btn btn-listo" (click)="activeModal.close()">Si</button>
        </div>
    </div>
</div>