import { Injectable } from '@angular/core';
import instruccionesJson from "./instrucciones.json";

@Injectable({
  providedIn: 'root'
})
export class ProblemGeneratorService {

  instrucciones2 = instruccionesJson;


  instrucciones = {
    nivel1: [
      '<p>Digita los números que se multiplicarán en los cuadros que se indican</p>',
      '<p>DIGITA LA RESERVA Y EL RESULTADO DE LA SUMA CORRESPONDIENTE A LAS UNIDADES</p>',
      '<p>DIGITA LA RESERVA Y EL RESULTADO DE LA SUMA CORRESPONDIENTE A LAS DECENAS</p>',
      '<p>DIGITA EL RESULTADO DE LA SUMA CORRESPONDIENTE A LAS CENTENAS</p>',
      '<p>DIGITA EL PRODUCTO EN EL CUADRO QUE SE INDICA</p>'
    ], nivel2: [
      '<p>Digita los números que se multiplicarán en los cuadros que se indican</p>',
      '<p>Digita en los cuadros que se indican el resultado de <b><i>$Y veces $X</i></b>&nbsp;&nbsp;&nbsp;ó&nbsp;&nbsp;&nbsp;<b><i>$Y ⦁ $X</i></b></p>',
      '<p>Digita en los cuadros que se indican el resultado de <b><i>$Y veces $X</i></b>&nbsp;&nbsp;&nbsp;ó&nbsp;&nbsp;&nbsp;<b><i>$Y ⦁ $X</i></b> incluyendo la reserva</p>',
      '<p>Digita en el cuadro que se indican el resultado de la centena</p>',
      '<p>DIGITA EL PRODUCTO TOTAL EN EL CUADRO QUE SE INDICA</p>'
    ], nivel3: [
      '<p>Digita los números que se multiplicarán en los cuadros que se indican</p>',
      '<p>Digita en los cuadros que se indican el resultado de <b><i>$Y veces $X</i></b>&nbsp;&nbsp;&nbsp;ó&nbsp;&nbsp;&nbsp;<b><i>$Y ⦁ $X</i></b></p>',
      '<p>Digita en los cuadros que se indican el resultado de <b><i>$Y veces $X</i></b>&nbsp;&nbsp;&nbsp;ó&nbsp;&nbsp;&nbsp;<b><i>$Y ⦁ $X</i></b> incluyendo la reserva</p>',
      '<p>Digita en los cuadros que se indican el resultado de <b><i>$Y veces $X</i></b>&nbsp;&nbsp;&nbsp;ó&nbsp;&nbsp;&nbsp;<b><i>$Y ⦁ $X</i></b> incluyendo la reserva</p>',
      '<p>Digita en el cuadro que se indican el resultado de la unidad de mil</p>',
      '<p>DIGITA EL PRODUCTO TOTAL EN EL CUADRO QUE SE INDICA</p>'
    ], nivel4: [
      '<p>Digita los números que se multiplicarán en los cuadros que se indican</p></p>',
      '<p>PARA RESOLVER ESTA MULTIPLICACIÓN, LO HAREMOS POR PARTES, COMENZAREMOS MULTIPLICANDO EL <b><i>$Z POR LA UNIDAD DEL SEGUNDO FACTOR</i></b>.</p>${split}<p>Digita en los cuadros que se indican el resultado de <b><i>$Y veces $X</i></b>&nbsp;&nbsp;&nbsp;ó&nbsp;&nbsp;&nbsp;<b><i>$Y ⦁ $X</i></b></p>',
      '<p>Digita en los cuadros que se indican el resultado de <b><i>$Y veces $X</i></b>&nbsp;&nbsp;&nbsp;ó&nbsp;&nbsp;&nbsp;<b><i>$Y ⦁ $X</i></b> incluyendo la reserva</p>',
      '<p>Digita en el cuadro que se indican el resultado de la centena</p>',
      '<p>AHORA CONTINUAREMOS MULTIPLICANDO EL <b><i>$Z POR LA DECENA DEL SEGUNDO FACTOR</i></b>.</p>${split}<p>Digita en los cuadros que se indican el resultado de <b><i>$Y veces $X</i></b>&nbsp;&nbsp;&nbsp;ó&nbsp;&nbsp;&nbsp;<b><i>$Y ⦁ $X</i></b></p>',
      '<p>Digita en los cuadros que se indican el resultado de <b><i>$Y veces $X</i></b>&nbsp;&nbsp;&nbsp;ó&nbsp;&nbsp;&nbsp;<b><i>$Y ⦁ $X</i></b> incluyendo la reserva</p>',
      '<p>Digita en el cuadro que se indican el resultado de la centena</p>',
      '<p>FINALMENTE, SUMAREMOS LOS RESULTADOS ANTERIORES. DIGITA EN EL CUADRO QUE SE INDICA, EL RESULTADO DE LA SUMA CORRESPONDIENTE A LAS UNIDADES</p>',
      '<p>DIGITA EN LOS CUADROS QUE SE INDICAN, EL RESULTADO DE LA SUMA CORRESPONDIENTE A LAS DECENAS</p>',
      '<p>DIGITA EN LOS CUADROS QUE SE INDICAN, EL RESULTADO DE LA SUMA CORRESPONDIENTE A LAS CENTENAS</p>',
      '<p>DIGITA EN LOS CUADROS QUE SE INDICAN, EL RESULTADO DE LA SUMA CORRESPONDIENTE A LAS UNIDADES DE MIL</p>',
      '<p>DIGITA EL RESULTADO FINAL EN EL CUADRO QUE SE INDICA</p>'
    ], nivel5: [
      '<p>Digita los números que se multiplicarán en los cuadros que se indican</p>',
      '<p>PARA RESOLVER ESTA MULTIPLICACIÓN, LO HAREMOS POR PARTES, COMENZAREMOS MULTIPLICANDO EL <b><i>$Z POR LA UNIDAD DEL SEGUNDO FACTOR</i></b>.</p>${split}<p>Digita en los cuadros que se indican el resultado de <b><i>$Y veces $X</i></b>&nbsp;&nbsp;&nbsp;ó&nbsp;&nbsp;&nbsp;<b><i>$Y ⦁ $X</i></b></p>',
      '<p>Digita en los cuadros que se indican el resultado de <b><i>$Y veces $X</i></b>&nbsp;&nbsp;&nbsp;ó&nbsp;&nbsp;&nbsp;<b><i>$Y ⦁ $X</i></b> incluyendo la reserva</p>',
      '<p>Digita en los cuadros que se indican el resultado de <b><i>$Y veces $X</i></b>&nbsp;&nbsp;&nbsp;ó&nbsp;&nbsp;&nbsp;<b><i>$Y ⦁ $X</i></b> incluyendo la reserva</p>',
      '<p>Digita en el cuadro que se indican el resultado de la unidad de mil</p>',
      '<p>AHORA CONTINUAREMOS MULTIPLICANDO EL <b><i>$Z POR LA DECENA DEL SEGUNDO FACTOR<i></b>. </p>${split}<p>Digita en los cuadros que se indican el resultado de <b><i>$Y veces $X</i></b>&nbsp;&nbsp;&nbsp;ó&nbsp;&nbsp;&nbsp;<b><i>$Y ⦁ $X</i></b></p>',
      '<p>Digita en los cuadros que se indican el resultado de <b><i>$Y veces $X</i></b>&nbsp;&nbsp;&nbsp;ó&nbsp;&nbsp;&nbsp;<b><i>$Y ⦁ $X</i></b> incluyendo la reserva</p>',
      '<p>Digita en los cuadros que se indican el resultado de <b><i>$Y veces $X</i></b>&nbsp;&nbsp;&nbsp;ó&nbsp;&nbsp;&nbsp;<b><i>$Y ⦁ $X</i></b> incluyendo la reserva</p>',
      '<p>Digita en el cuadro que se indican el resultado de la unidad de mil</p>',
      '<p>FINALMENTE, SUMAREMOS LOS RESULTADOS ANTERIORES. </p><p>DIGITA EN EL CUADRO QUE SE INDICA, EL RESULTADO DE LA SUMA CORRESPONDIENTE A LAS UNIDADES</p>',
      '<p>DIGITA EN LOS CUADROS QUE SE INDICAN, EL RESULTADO DE LA SUMA CORRESPONDIENTE A LAS DECENAS</p>',
      '<p>DIGITA EN LOS CUADROS QUE SE INDICAN, EL RESULTADO DE LA SUMA CORRESPONDIENTE A LAS CENTENAS</p>',
      '<p>DIGITA EN LOS CUADROS QUE SE INDICAN, EL RESULTADO DE LA SUMA CORRESPONDIENTE A LAS UNIDADES DE MIL</p>',
      '<p>DIGITA EN LOS CUADROS QUE SE INDICAN, EL RESULTADO DE LA SUMA CORRESPONDIENTE A LAS DECENAS DE MIL</p>',
      'DIGITA EL RESULTADO FINAL EN EL CUADRO QUE SE INDICA</p>'
    ], nivel6: [
      '<p>Digita los números que se multiplicarán en los cuadros que se indican</p>',
      '<p>PARA RESOLVER ESTA MULTIPLICACIÓN, LO HAREMOS POR PARTES, COMENZAREMOS MULTIPLICANDO EL <b><i>$Z POR LA UNIDAD DEL SEGUNDO FACTOR</i><b>.</p>${split}<p>Digita en los cuadros que se indican el resultado de <b><i>$Y veces $X</i></b>&nbsp;&nbsp;&nbsp;ó&nbsp;&nbsp;&nbsp;<b><i>$Y ⦁ $X</i></b></p>',
      '<p>Digita en los cuadros que se indican el resultado de <b><i>$Y veces $X</i></b>&nbsp;&nbsp;&nbsp;ó&nbsp;&nbsp;&nbsp;<b><i>$Y ⦁ $X</i></b> incluyendo la reserva</p>',
      '<p>Digita en los cuadros que se indican el resultado de <b><i>$Y veces $X</i></b>&nbsp;&nbsp;&nbsp;ó&nbsp;&nbsp;&nbsp;<b><i>$Y ⦁ $X</i></b> incluyendo la reserva</p>',
      '<p>Digita en los cuadros que se indican el resultado de <b><i>$Y veces $X</i></b>&nbsp;&nbsp;&nbsp;ó&nbsp;&nbsp;&nbsp;<b><i>$Y ⦁ $X</i></b> incluyendo la reserva</p>',
      '<p>Digita en el cuadro que se indican el resultado de la decena de mil</p>',
      '<p>AHORA CONTINUAREMOS MULTIPLICANDO EL <b><i>$Z POR LA DECENA DEL SEGUNDO FACTOR</i></b>.</p>${split}<p>Digita en los cuadros que se indican el resultado de <b><i>$Y veces $X</i></b>&nbsp;&nbsp;&nbsp;ó&nbsp;&nbsp;&nbsp;<b><i>$Y ⦁ $X</i></b></p>',
      '<p>Digita en los cuadros que se indican el resultado de <b><i>$Y veces $X</i></b>&nbsp;&nbsp;&nbsp;ó&nbsp;&nbsp;&nbsp;<b><i>$Y ⦁ $X</i></b> incluyendo la reserva</p>',
      '<p>Digita en los cuadros que se indican el resultado de <b><i>$Y veces $X</i></b>&nbsp;&nbsp;&nbsp;ó&nbsp;&nbsp;&nbsp;<b><i>$Y ⦁ $X</i></b> incluyendo la reserva</p>',
      '<p>Digita en los cuadros que se indican el resultado de <b><i>$Y veces $X</i></b>&nbsp;&nbsp;&nbsp;ó&nbsp;&nbsp;&nbsp;<b><i>$Y ⦁ $X</i></b> incluyendo la reserva</p>',
      '<p>Digita en el cuadro que se indican el resultado de la decena de mil</p>',
      '<p>AHORA CONTINUAREMOS MULTIPLICANDO EL <b><i>$Z POR LA CENTENA DEL SEGUNDO FACTOR</i></b>.</p>${split}<p>Digita en los cuadros que se indican el resultado de <b><i>$Y veces $X</i></b>&nbsp;&nbsp;&nbsp;ó&nbsp;&nbsp;&nbsp;<b><i>$Y ⦁ $X</i></b></p>',
      '<p>Digita en los cuadros que se indican el resultado de <b><i>$Y veces $X</i></b>&nbsp;&nbsp;&nbsp;ó&nbsp;&nbsp;&nbsp;<b><i>$Y ⦁ $X</i></b> incluyendo la reserva</p>',
      '<p>Digita en los cuadros que se indican el resultado de <b><i>$Y veces $X</i></b>&nbsp;&nbsp;&nbsp;ó&nbsp;&nbsp;&nbsp;<b><i>$Y ⦁ $X</i></b> incluyendo la reserva</p>',
      '<p>Digita en los cuadros que se indican el resultado de <b><i>$Y veces $X</i></b>&nbsp;&nbsp;&nbsp;ó&nbsp;&nbsp;&nbsp;<b><i>$Y ⦁ $X</i></b> incluyendo la reserva</p>',
      '<p>Digita en el cuadro que se indican el resultado de la decena de mil</p>',
      '<p>FINALMENTE, SUMAREMOS LOS RESULTADOS ANTERIORES. DIGITA EN EL CUADRO QUE SE INDICA, EL RESULTADO DE LA SUMA CORRESPONDIENTE A LAS UNIDADES</p>',
      '<p>DIGITA EN LOS CUADROS QUE SE INDICAN, EL RESULTADO DE LA SUMA CORRESPONDIENTE A LAS DECENAS</p>',
      '<p>DIGITA EN LOS CUADROS QUE SE INDICAN, EL RESULTADO DE LA SUMA CORRESPONDIENTE A LAS CENTENAS</p>',
      '<p>DIGITA EN LOS CUADROS QUE SE INDICAN, EL RESULTADO DE LA SUMA CORRESPONDIENTE A LAS UNIDADES DE MIL</p>',
      '<p>DIGITA EN LOS CUADROS QUE SE INDICAN, EL RESULTADO DE LA SUMA CORRESPONDIENTE A LAS DECENAS DE MIL</p>',
      '<p>DIGITA EN LOS CUADROS QUE SE INDICAN, EL RESULTADO DE LA SUMA CORRESPONDIENTE A LAS CENTENAS DE MIL</p>',
      '<p>DIGITA EN LOS CUADROS QUE SE INDICAN, EL RESULTADO DE LA SUMA CORRESPONDIENTE A LAS UNIDADES DE MILLÓN</p>',
      '<p>DIGITA EL RESULTADO FINAL EN EL CUADRO QUE SE INDICA</p>'
    ]
  }

  constructor() { }

  generarNivel1(){
    let problema: any = {
      valor1: [0,0],
      valor2: [0],
      resultado: 0,
      final: { valores: [], multiplicador: [], resultado: ['','',''], reserva: ['','',''] },
      steps: [{ valores: [[0, 0]], multiplicador: [0]}, {resultado: 0, reserva: 0}, { resultado: 0, reserva: 0 }, { resultado: 0 }]
    }
    let a = Math.floor(Math.random() * 9) + 1;
    let b = Math.floor(Math.random() * 10);
    let x = Math.floor(Math.random() * 7) + 2;
    while (b * x <= 9) {
      b = Math.floor(Math.random() * 10);
      x = Math.floor(Math.random() * 7) + 2;
    }
    // a = 7; b=2; x=5;
    // a = 1; b=1; x=1;
    // a = 1; b=0; x=1;
    problema.valor1 = [a, b];
    problema.valor2 = [x];
    problema.resultado = (a * 10 + b) * x;
    problema.final.valores = [['', a, b]];
    problema.final.multiplicador = [x];
    let aux: number = b * x;
    problema.steps[1].resultado = this.obtenerDigito(aux, 0);
    problema.steps[1].reserva = this.obtenerDigito(aux, 1);
    problema.final.resultado[2] = this.obtenerDigito(aux, 0);
    if (this.obtenerDigito(aux, 1)) problema.final.reserva[1] = this.obtenerDigito(aux, 1);
    aux = (a * x) + problema.steps[1].reserva;
    problema.steps[2].resultado = this.obtenerDigito(aux, 0);
    problema.steps[2].reserva = this.obtenerDigito(aux, 1);
    problema.final.resultado[1] = this.obtenerDigito(aux, 0);
    if (this.obtenerDigito(aux, 1)) problema.final.reserva[0] = this.obtenerDigito(aux, 1);
    if (this.obtenerDigito(aux, 1)) problema.final.resultado[0] = this.obtenerDigito(aux, 1);
    if (aux >= 10) problema.steps[3].resultado = problema.steps[2].reserva;
    if (problema.steps[0].valores && problema.steps[0].valores.length > 0) problema.steps[0].valores[0] = problema.valor1;
    problema.steps[0].multiplicador = problema.valor2;
    return problema;
  }

  generarNivel2() {
    let problema: any = {
      valor1: [0, 0],
      valor2: [0],
      resultado: 0,
      final: { valores: [], multiplicador: [], resultado: ['', '', ''], reserva: ['', '', ''] },
      steps: [{ valores: [[0, 0]], multiplicador: [0] }, { resultado: 0, reserva: 0 }, { resultado: 0, reserva: 0 }, { resultado: 0 }]
    }
    let a = Math.floor(Math.random() * 9) + 1;
    let b = Math.floor(Math.random() * 10);
    let x = Math.floor(Math.random() * 7) + 2;
    while (b * x <= 9) {
      b = Math.floor(Math.random() * 10);
      x = Math.floor(Math.random() * 7) + 2;
    }
    // a = 9; b=9; x=2;
    // a = 1; b=1; x=1;
    // a = 1; b=0; x=1;
    problema.valor1 = [a, b];
    problema.valor2 = [x];
    problema.resultado = (a * 10 + b) * x;
    problema.final.valores = [['', a, b]];
    problema.final.multiplicador = [x];
    let aux: number = b * x;
    problema.steps[1].resultado = this.obtenerDigito(aux, 0);
    problema.steps[1].reserva = this.obtenerDigito(aux, 1);
    problema.final.resultado[2] = this.obtenerDigito(aux, 0);
    if (this.obtenerDigito(aux, 1)) problema.final.reserva[1] = this.obtenerDigito(aux, 1);
    aux = (a * x) + problema.steps[1].reserva;
    problema.steps[2].resultado = this.obtenerDigito(aux, 0);
    problema.steps[2].reserva = this.obtenerDigito(aux, 1);
    problema.final.resultado[1] = this.obtenerDigito(aux, 0);
    if (this.obtenerDigito(aux, 1)) problema.final.reserva[0] = this.obtenerDigito(aux, 1);
    if (this.obtenerDigito(aux, 1)) problema.final.resultado[0] = this.obtenerDigito(aux, 1);
    if (aux >= 10) problema.steps[3].resultado = problema.steps[2].reserva;
    if (problema.steps[0].valores && problema.steps[0].valores.length > 0) problema.steps[0].valores[0] = problema.valor1;
    problema.steps[0].multiplicador = problema.valor2;
    return problema;
  }

  generarNivel25() {
    let problema = this.generarNivel2();
    problema.steps = [problema.steps[problema.steps.length-1]];
    return problema;
  }

  generarNivel3() {
    let problema: any = {
      valor1: [0, 0, 0],
      valor2: [0],
      resultado: 0,
      final: { valores: [], multiplicador: [], resultado: ['', '', ''], reserva: ['', '', ''] },
      steps: [{ valores: [[0, 0, 0]], multiplicador: [0] }, { resultado: 0, reserva: 0 }, { resultado: 0, reserva: 0 }, { resultado: 0, reserva: 0 }, { resultado: 0 }]
    }
    let a = Math.floor(Math.random() * 9) + 1;
    let b = Math.floor(Math.random() * 9) + 1;
    let c = Math.floor(Math.random() * 10);
    let x = Math.floor(Math.random() * 5) + 2;
    while (c * x <= 9) {
      c = Math.floor(Math.random() * 10);
      x = Math.floor(Math.random() * 5) + 2;
    }
    // a = 7; b=5; c=5; x=6;
    // a = 1; b=1; c=1; x=1;
    // a = 1; b=0; c=0; x=1;
    problema.valor1 = [a, b, c];
    problema.valor2 = [x];
    problema.final.valores = [['', a, b, c]];
    problema.final.multiplicador = [x];
    problema.resultado = (a * 100 + b * 10 + c) * x;
    let aux: number = c * x;
    problema.steps[1].resultado = this.obtenerDigito(aux, 0);
    problema.steps[1].reserva = this.obtenerDigito(aux, 1);
    problema.final.resultado[3] = this.obtenerDigito(aux, 0);

    if (this.obtenerDigito(aux, 1)) problema.final.reserva[2] = this.obtenerDigito(aux, 1);
    aux = (b * x) + problema.steps[1].reserva;
    problema.steps[2].resultado = this.obtenerDigito(aux, 0);
    problema.steps[2].reserva = this.obtenerDigito(aux, 1);
    problema.final.resultado[2] = this.obtenerDigito(aux, 0);

    if (this.obtenerDigito(aux, 1)) problema.final.reserva[1] = this.obtenerDigito(aux, 1);
    aux = (a * x) + problema.steps[2].reserva;
    problema.steps[3].resultado = this.obtenerDigito(aux, 0);
    problema.steps[3].reserva = this.obtenerDigito(aux, 1);
    problema.final.resultado[1] = this.obtenerDigito(aux, 0);

    if (this.obtenerDigito(aux, 1)) problema.final.reserva[0] = this.obtenerDigito(aux, 1);
    if (this.obtenerDigito(aux, 1)) problema.final.reserva[0] = this.obtenerDigito(aux, 1);
    if (aux >= 10) {
      problema.steps[4].resultado = problema.steps[3].reserva;
      problema.final.resultado[0] = problema.steps[3].reserva;
    }
      

    if (problema.steps[0].valores && problema.steps[0].valores.length > 0) problema.steps[0].valores[0] = problema.valor1;
    problema.steps[0].multiplicador = problema.valor2;
    return problema;
  }

  generarNivel35() {
    let problema = this.generarNivel3();
    problema.steps = [problema.steps[problema.steps.length - 1]];
    return problema;
  }

  generarNivel4() {
    let problema: any = {
      valor1: [0, 0],
      valor2: [0, 0],
      resultado: 0,
      final: { valores: [], multiplicador: [], resultado: ['', '', ''], reserva: ['', '', ''] },
      steps: [{ valores: [[0, 0], ['', ''], ['', '']], multiplicador: [0, 0] }, { resultado: 0, reserva: 0 }, { resultado: 0, reserva: 0 }, { resultado: 0 }, { resultado: 0, reserva: 0 }, { resultado: 0, reserva: 0 }, { resultado: 0 }, { resultado: 0, reserva: 0 }, { resultado: 0, reserva: 0 }, { resultado: 0, reserva: 0 }, { resultado: 0 }],
      steps1: [{ valores: [[0, 0]], multiplicador: [0] }, { resultado: 0, reserva: 0 }, { resultado: 0, reserva: 0 }, { resultado: 0 }],
      steps2: [{ valores: [[0, 0]], multiplicador: [0] }, { resultado: 0, reserva: 0 }, { resultado: 0, reserva: 0 }, { resultado: 0 }],
    }
    let b = Math.floor(Math.random() * 9) + 1;
    let c = Math.floor(Math.random() * 10);
    let x = Math.floor(Math.random() * 9) + 1;
    let y = Math.floor(Math.random() * 7) + 2;
    while (c * y <= 9) {
      c = Math.floor(Math.random() * 10);
      y = Math.floor(Math.random() * 7) + 2;
    }
    // b = 1; c=4; x=3; y=6;
    // b = 1; c=1; x=1; y=1;
    // b = 1; c=0; x=1; y=0;
    problema.valor1 = [b, c];
    problema.valor2 = [x, y];
    problema.final.valores = [['', '', b, c], [], []];
    problema.final.multiplicador = [x, y];
    problema.resultado = (b * 10 + c) * (x * 10 + y);
    // Step 1
    let aux: number = c * y;
    problema.steps[1].resultado = this.obtenerDigito(aux, 0);
    problema.steps[1].reserva = this.obtenerDigito(aux, 1);
    problema.steps1[1].resultado = this.obtenerDigito(aux, 0);
    problema.steps1[1].reserva = this.obtenerDigito(aux, 1);
    problema.final.valores[1][2] = this.obtenerDigito(aux, 0);

    aux = (b * y) + problema.steps[1].reserva;
    problema.steps[2].resultado = this.obtenerDigito(aux, 0);
    problema.steps[2].reserva = this.obtenerDigito(aux, 1);
    problema.steps1[2].resultado = this.obtenerDigito(aux, 0);
    problema.steps1[2].reserva = this.obtenerDigito(aux, 1);
    problema.final.valores[1][1] = this.obtenerDigito(aux, 0);

    if (aux >= 10) {
      problema.steps[3].resultado = problema.steps[2].reserva;
      problema.steps1[3].resultado = problema.steps1[2].reserva;
      problema.final.valores[1][0] = this.obtenerDigito(aux, 0);
    }

    // Step 2
    aux = (c * x);
    problema.steps[4].resultado = this.obtenerDigito(aux, 0);
    problema.steps[4].reserva = this.obtenerDigito(aux, 1);
    problema.steps2[1].resultado = this.obtenerDigito(aux, 0);
    problema.steps2[1].reserva = this.obtenerDigito(aux, 1);
    problema.final.valores[2][2] = this.obtenerDigito(aux, 0);

    aux = (b * x) + problema.steps[4].reserva;
    problema.steps[5].resultado = this.obtenerDigito(aux, 0);
    problema.steps[5].reserva = this.obtenerDigito(aux, 1);
    problema.steps2[2].resultado = this.obtenerDigito(aux, 0);
    problema.steps2[2].reserva = this.obtenerDigito(aux, 1);
    problema.final.valores[2][1] = this.obtenerDigito(aux, 0);

    if (aux >= 10) {
      problema.steps[6].resultado = problema.steps[5].reserva;
      problema.steps2[3].resultado = problema.steps[5].reserva;
      problema.final.valores[2][0] = this.obtenerDigito(aux, 0);
    }
    if (problema.steps[0].valores && problema.steps[0].valores.length > 0) problema.steps[0].valores[0] = problema.valor1;
    if (problema.steps1[0].valores && problema.steps1[0].valores.length > 0) problema.steps1[0].valores[0] = problema.valor1;
    if (problema.steps2[0].valores && problema.steps2[0].valores.length > 0) problema.steps2[0].valores[0] = problema.valor1;
    problema.steps[0].multiplicador = problema.valor2;
    problema.steps1[0].multiplicador = [problema.valor2[1]];
    problema.steps2[0].multiplicador = [problema.valor2[0]];

    // Steps siguientes al 7
    aux = problema.steps1[1].resultado;
    problema.steps[7].resultado = aux;
    problema.final.resultado[3] = this.obtenerDigito(aux, 0);

    aux = problema.steps1[2].resultado + problema.steps2[1].resultado;
    problema.steps[8].resultado = this.obtenerDigito(aux, 0);
    problema.steps[8].reserva = this.obtenerDigito(aux, 1);
    problema.final.resultado[2] = this.obtenerDigito(aux, 0);

    if (problema.steps1[3] && problema.steps1[3].resultado || problema.steps1[3].resultado == 0) aux = problema.steps1[3].resultado + problema.steps2[2].resultado + problema.steps[8].reserva;
    problema.steps[9].resultado = this.obtenerDigito(aux, 0);
    problema.final.resultado[1] = this.obtenerDigito(aux, 0);

    problema.steps[9].reserva = this.obtenerDigito(aux, 1);
    if (problema.steps2[3] && problema.steps2[3].resultado || problema.steps2[3].resultado == 0) aux = problema.steps2[3].resultado + problema.steps[9].reserva;
    problema.steps[10].resultado = this.obtenerDigito(aux, 0);
    problema.final.resultado[0] = this.obtenerDigito(aux, 0);
    return problema;
  }

  generarNivel45() {
    let problema = this.generarNivel4();
    problema.steps = [problema.steps[problema.steps.length - 1]];
    return problema;
  }

  generarNivel5() {
    let problema: any = {
      valor1: [0, 0, 0],
      valor2: [0, 0],
      resultado: 0,
      final: { valores: [], multiplicador: [], resultado: ['', '', '', '', ''], reserva: ['', '', '', ''] },
      steps: [
        { valores: [[0, 0, 0], ['', '', ''], ['', '', ''], ['', '', '']], multiplicador: [0, 0] }, 
        { resultado: 0, reserva: 0 }, 
        { resultado: 0, reserva: 0 }, 
        { resultado: 0, reserva: 0 }, 
        { resultado: 0 }, 
        { resultado: 0, reserva: 0 }, 
        { resultado: 0, reserva: 0 }, 
        { resultado: 0, reserva: 0 }, 
        { resultado: 0 }, 
        { resultado: 0 }, 
        { resultado: 0, reserva: 0 },
        { resultado: 0, reserva: 0 }, 
        { resultado: 0, reserva: 0 }, 
        { resultado: '' }
      ],
      steps1: [
        { valores: [[0, 0]], multiplicador: [0] }, 
        { resultado: 0, reserva: 0 },
        { resultado: 0, reserva: 0 }, 
        { resultado: 0, reserva: 0 }, 
        { resultado: 0 }
      ],
      steps2: [
        { valores: [[0, 0]], multiplicador: [0] }, 
        { resultado: 0, reserva: 0 },
        { resultado: 0, reserva: 0 }, 
        { resultado: 0, reserva: 0 }, 
        { resultado: 0 }
      ],
    }
    let a = Math.floor(Math.random() * 9) + 1;
    let b = Math.floor(Math.random() * 9) + 1;
    let c = Math.floor(Math.random() * 10);
    let x = Math.floor(Math.random() * 9) + 1;
    let y = Math.floor(Math.random() * 7) + 2;
    while (c * y <= 9) {
      c = Math.floor(Math.random() * 10);
      y = Math.floor(Math.random() * 7) + 2;
    }
    // a=7; b = 1; c = 9; x = 5; y = 8;
    // a=1;b = 1; c = 1; x = 1; y = 1;
    // a = 1; b = 0; c = 0; x = 1; y = 0;
    // a = 1; b = 1; c = 0; x = 9; y = 5;
    problema.valor1 = [a, b, c];
    problema.valor2 = [x, y];
    problema.resultado = (a * 100 + b * 10 + c) * (x * 10 + y);
    problema.final.valores = [['', '', a, b, c], [], []];
    problema.final.multiplicador = [x, y];
    // Step 1
    let aux: number = c * y;
    problema.steps[1].resultado = this.obtenerDigito(aux, 0);
    problema.steps[1].reserva = this.obtenerDigito(aux, 1);
    problema.steps1[1].resultado = this.obtenerDigito(aux, 0);
    problema.steps1[1].reserva = this.obtenerDigito(aux, 1);
    problema.final.valores[1][3] = this.obtenerDigito(aux, 0);

    aux = (b * y) + problema.steps[1].reserva;
    problema.steps[2].resultado = this.obtenerDigito(aux, 0);
    problema.steps[2].reserva = this.obtenerDigito(aux, 1);
    problema.steps1[2].resultado = this.obtenerDigito(aux, 0);
    problema.steps1[2].reserva = this.obtenerDigito(aux, 1);
    problema.final.valores[1][2] = this.obtenerDigito(aux, 0);

    aux = (a * y) + problema.steps[2].reserva;
    problema.steps[3].resultado = this.obtenerDigito(aux, 0);
    problema.steps[3].reserva = this.obtenerDigito(aux, 1);
    problema.steps1[3].resultado = this.obtenerDigito(aux, 0);
    problema.steps1[3].reserva = this.obtenerDigito(aux, 1);
    problema.final.valores[1][1] = this.obtenerDigito(aux, 0);

    if (aux >= 10) {
      problema.steps[4].resultado = problema.steps[3].reserva;
      problema.steps1[4].resultado = problema.steps1[3].reserva;
      problema.final.valores[1][0] = problema.steps[3].reserva;
    }
    // Step 2
    aux = (c * x);
    problema.steps[5].resultado = this.obtenerDigito(aux, 0);
    problema.steps[5].reserva = this.obtenerDigito(aux, 1);
    problema.steps2[1].resultado = this.obtenerDigito(aux, 0);
    problema.steps2[1].reserva = this.obtenerDigito(aux, 1);
    problema.final.valores[2][3] = this.obtenerDigito(aux, 0);

    aux = (b * x) + problema.steps[5].reserva;
    problema.steps[6].resultado = this.obtenerDigito(aux, 0);
    problema.steps[6].reserva = this.obtenerDigito(aux, 1);
    problema.steps2[2].resultado = this.obtenerDigito(aux, 0);
    problema.steps2[2].reserva = this.obtenerDigito(aux, 1);
    problema.final.valores[2][2] = this.obtenerDigito(aux, 0);

    aux = (a * x) + problema.steps[6].reserva;
    problema.steps[7].resultado = this.obtenerDigito(aux, 0);
    problema.steps[7].reserva = this.obtenerDigito(aux, 1);
    problema.steps2[3].resultado = this.obtenerDigito(aux, 0);
    problema.steps2[3].reserva = this.obtenerDigito(aux, 1);
    problema.final.valores[2][1] = this.obtenerDigito(aux, 0);

    if (aux >= 10) {
      problema.steps[8].resultado = problema.steps[7].reserva;
      problema.steps2[4].resultado = problema.steps[7].reserva;
      problema.final.valores[2][0] = problema.steps[7].reserva;
    }

    // Asignar valores base
    if (problema.steps[0].valores && problema.steps[0].valores.length > 0) problema.steps[0].valores[0] = problema.valor1;
    if (problema.steps1[0].valores && problema.steps1[0].valores.length > 0) problema.steps1[0].valores[0] = problema.valor1;
    if (problema.steps2[0].valores && problema.steps2[0].valores.length > 0) problema.steps2[0].valores[0] = problema.valor1;
    problema.steps[0].multiplicador = problema.valor2;
    problema.steps1[0].multiplicador = [problema.valor2[1]];
    problema.steps2[0].multiplicador = [problema.valor2[0]];
    // Steps de suma
    // Suma de unidades
    aux = problema.steps1[1].resultado;
    problema.steps[9].resultado = aux;
    problema.final.resultado[4] = aux;
    // Suma de decenas
    aux = problema.steps1[2].resultado + problema.steps2[1].resultado;
    problema.steps[10].resultado = this.obtenerDigito(aux, 0);
    problema.steps[10].reserva = this.obtenerDigito(aux, 1);
    problema.final.resultado[3] = this.obtenerDigito(aux, 0);
    // Suma de centenas
    if (problema.steps1[3] && problema.steps1[3].resultado || problema.steps1[3].resultado == 0) aux = problema.steps1[3].resultado + problema.steps2[2].resultado + problema.steps[10].reserva;
    problema.steps[11].resultado = this.obtenerDigito(aux, 0);
    problema.steps[11].reserva = this.obtenerDigito(aux, 1);
    problema.final.resultado[2] = this.obtenerDigito(aux, 0);
    // Suma de unidades de mil
    if (problema.steps1[4] && problema.steps1[4].resultado || problema.steps1[4].resultado == 0) aux = problema.steps1[4].resultado + problema.steps2[3].resultado + problema.steps[11].reserva;
    problema.steps[12].resultado = this.obtenerDigito(aux, 0);
    problema.steps[12].reserva = this.obtenerDigito(aux, 1);
    problema.final.resultado[1] = this.obtenerDigito(aux, 0);
    // Suma de decenas de mil
    if (problema.steps2[4] && problema.steps2[4].resultado || problema.steps2[4].resultado == 0) {
      aux = problema.steps2[4].resultado + problema.steps[12].reserva;
      problema.steps[13].resultado = this.obtenerDigito(aux, 0);
      problema.final.resultado[0] = this.obtenerDigito(aux, 0);
    }
    return problema;
  }

  generarNivel55() {
    let problema = this.generarNivel5();
    problema.steps = [problema.steps[problema.steps.length - 1]];
    return problema;
  }

  generarNivel6() {
    let problema: any = {
      valor1: [0, 0, 0, 0],
      valor2: [0, 0, 0],
      resultado: 0,
      final: { valores: [], multiplicador: [], resultado: ['', '', '', '', '', '', ''], reserva: ['', '', '', '', ''] },
      steps: [
        { valores: [[0, 0, 0, 0], ['', '', '', ''], ['', '', '', ''], ['', '', '', '']], multiplicador: [0, 0, 0] },
        { resultado: 0, reserva: 0 },
        { resultado: 0, reserva: 0 },
        { resultado: 0, reserva: 0 },
        { resultado: 0, reserva: 0 },
        { resultado: 0 },
        { resultado: 0, reserva: 0 },
        { resultado: 0, reserva: 0 },
        { resultado: 0, reserva: 0 },
        { resultado: 0, reserva: 0 },
        { resultado: 0 },
        { resultado: 0, reserva: 0 },
        { resultado: 0, reserva: 0 },
        { resultado: 0, reserva: 0 },
        { resultado: 0, reserva: 0 },
        { resultado: 0 },
        { resultado: 0 },
        { resultado: 0, reserva: 0 },
        { resultado: 0, reserva: 0 },
        { resultado: 0, reserva: 0 },
        { resultado: 0, reserva: 0 },
        { resultado: 0, reserva: 0 },
        { resultado: 0 }
      ],
      steps1: [
        { valores: [[0, 0, 0, 0]], multiplicador: [0] },
        { resultado: 0, reserva: 0 },
        { resultado: 0, reserva: 0 },
        { resultado: 0, reserva: 0 },
        { resultado: 0, reserva: 0 },
        { resultado: 0 }
      ],
      steps2: [
        { valores: [[0, 0, 0, 0]], multiplicador: [0] },
        { resultado: 0, reserva: 0 },
        { resultado: 0, reserva: 0 },
        { resultado: 0, reserva: 0 },
        { resultado: 0, reserva: 0 },
        { resultado: 0 }
      ],
      steps3: [
        { valores: [[0, 0, 0, 0]], multiplicador: [0] },
        { resultado: 0, reserva: 0 },
        { resultado: 0, reserva: 0 },
        { resultado: 0, reserva: 0 },
        { resultado: 0, reserva: 0 },
        { resultado: 0 }
      ],
    }
    let aum = Math.floor(Math.random() * 9) + 1;
    let ac = Math.floor(Math.random() * 9) + 1;
    let ad = Math.floor(Math.random() * 9) + 1;
    let au = Math.floor(Math.random() * 10);
    let xc = Math.floor(Math.random() * 9) + 1;
    let xd = Math.floor(Math.random() * 9) + 1;
    let xu = Math.floor(Math.random() * 7) + 2;
    while (au * xu <= 9) {
      au = Math.floor(Math.random() * 10);
      xu = Math.floor(Math.random() * 7) + 2;
    }
    // aum = 8; ac =9; ad = 7; au = 2; xc = 9; xd = 5; xu = 7;
    // aum = 1; ac =1; ad = 1; au = 1; xc = 1; xd = 1; xu = 1;
    // aum = 1; ac =0; ad = 0; au = 0; xc = 1; xd = 0; xu = 0;
    // aum = 1; ac = 1; ad = 0; au = 0; xc = 5; xd = 9; xu = 0;
    // aum = 1; ac = 1; ad = 2; au = 0; xc = 5; xd = 9; xu = 9;
    problema.valor1 = [aum, ac, ad, au];
    problema.valor2 = [xc, xd, xu];
    problema.resultado = (aum * 1000 + ac * 100 + ad * 10 + au) * (xc * 100 + xd * 10 + xu);
    problema.final.valores = [['', '', '', aum, ac, ad, au], [], [], []];
    problema.final.multiplicador = [xc, xd, xu];
    // Step 1
    let aux: number = au * xu;
    problema.steps[1].resultado = this.obtenerDigito(aux, 0);
    problema.steps[1].reserva = this.obtenerDigito(aux, 1);
    problema.steps1[1].resultado = this.obtenerDigito(aux, 0);
    problema.steps1[1].reserva = this.obtenerDigito(aux, 1);
    problema.final.valores[1][4] = this.obtenerDigito(aux, 0);

    aux = (ad * xu) + problema.steps[1].reserva;
    problema.steps[2].resultado = this.obtenerDigito(aux, 0);
    problema.steps[2].reserva = this.obtenerDigito(aux, 1);
    problema.steps1[2].resultado = this.obtenerDigito(aux, 0);
    problema.steps1[2].reserva = this.obtenerDigito(aux, 1);
    problema.final.valores[1][3] = this.obtenerDigito(aux, 0);

    aux = (ac * xu) + problema.steps[2].reserva;
    problema.steps[3].resultado = this.obtenerDigito(aux, 0);
    problema.steps[3].reserva = this.obtenerDigito(aux, 1);
    problema.steps1[3].resultado = this.obtenerDigito(aux, 0);
    problema.steps1[3].reserva = this.obtenerDigito(aux, 1);
    problema.final.valores[1][2] = this.obtenerDigito(aux, 0);

    aux = (aum * xu) + problema.steps[3].reserva;
    problema.steps[4].resultado = this.obtenerDigito(aux, 0);
    problema.steps[4].reserva = this.obtenerDigito(aux, 1);
    problema.steps1[4].resultado = this.obtenerDigito(aux, 0);
    problema.steps1[4].reserva = this.obtenerDigito(aux, 1);
    problema.final.valores[1][1] = this.obtenerDigito(aux, 0);
    
    if (aux >= 10) {
      problema.steps[5].resultado = problema.steps[4].reserva;
      problema.steps1[5].resultado = problema.steps1[4].reserva;
      problema.final.valores[1][0] = problema.steps1[4].reserva;
    }
    // Step 2
    aux = (au * xd);
    problema.steps[6].resultado = this.obtenerDigito(aux, 0);
    problema.steps[6].reserva = this.obtenerDigito(aux, 1);
    problema.steps2[1].resultado = this.obtenerDigito(aux, 0);
    problema.steps2[1].reserva = this.obtenerDigito(aux, 1);
    problema.final.valores[2][4] = this.obtenerDigito(aux, 0);

    aux = (ad * xd) + problema.steps[6].reserva;
    problema.steps[7].resultado = this.obtenerDigito(aux, 0);
    problema.steps[7].reserva = this.obtenerDigito(aux, 1);
    problema.steps2[2].resultado = this.obtenerDigito(aux, 0);
    problema.steps2[2].reserva = this.obtenerDigito(aux, 1);
    problema.final.valores[2][3] = this.obtenerDigito(aux, 0);

    aux = (ac * xd) + problema.steps[7].reserva;
    problema.steps[8].resultado = this.obtenerDigito(aux, 0);
    problema.steps[8].reserva = this.obtenerDigito(aux, 1);
    problema.steps2[3].resultado = this.obtenerDigito(aux, 0);
    problema.steps2[3].reserva = this.obtenerDigito(aux, 1);
    problema.final.valores[2][2] = this.obtenerDigito(aux, 0);

    aux = (aum * xd) + problema.steps[8].reserva;
    problema.steps[9].resultado = this.obtenerDigito(aux, 0);
    problema.steps[9].reserva = this.obtenerDigito(aux, 1);
    problema.steps2[4].resultado = this.obtenerDigito(aux, 0);
    problema.steps2[4].reserva = this.obtenerDigito(aux, 1);
    problema.final.valores[2][1] = this.obtenerDigito(aux, 0);

    if (aux >= 10) {
      problema.steps[10].resultado = problema.steps[9].reserva;
      problema.steps2[5].resultado = problema.steps2[4].reserva;
      problema.final.valores[2][0] = problema.steps2[4].reserva;
    }
    // Step 3
    aux = (au * xc);
    problema.steps[11].resultado = this.obtenerDigito(aux, 0);
    problema.steps[11].reserva = this.obtenerDigito(aux, 1);
    problema.steps3[1].resultado = this.obtenerDigito(aux, 0);
    problema.steps3[1].reserva = this.obtenerDigito(aux, 1);
    problema.final.valores[3][4] = this.obtenerDigito(aux, 0);

    aux = (ad * xc) + problema.steps[11].reserva;
    problema.steps[12].resultado = this.obtenerDigito(aux, 0);
    problema.steps[12].reserva = this.obtenerDigito(aux, 1);
    problema.steps3[2].resultado = this.obtenerDigito(aux, 0);
    problema.steps3[2].reserva = this.obtenerDigito(aux, 1);
    problema.final.valores[3][3] = this.obtenerDigito(aux, 0);

    aux = (ac * xc) + problema.steps[12].reserva;
    problema.steps[13].resultado = this.obtenerDigito(aux, 0);
    problema.steps[13].reserva = this.obtenerDigito(aux, 1);
    problema.steps3[3].resultado = this.obtenerDigito(aux, 0);
    problema.steps3[3].reserva = this.obtenerDigito(aux, 1);
    problema.final.valores[3][2] = this.obtenerDigito(aux, 0);

    aux = (aum * xc) + problema.steps[13].reserva;
    problema.steps[14].resultado = this.obtenerDigito(aux, 0);
    problema.steps[14].reserva = this.obtenerDigito(aux, 1);
    problema.steps3[4].resultado = this.obtenerDigito(aux, 0);
    problema.steps3[4].reserva = this.obtenerDigito(aux, 1);
    problema.final.valores[3][1] = this.obtenerDigito(aux, 0);

    if (aux >= 10) {
      problema.steps[15].resultado = problema.steps[14].reserva;
      problema.steps3[5].resultado = problema.steps3[4].reserva;
      problema.final.valores[3][0] = problema.steps[14].reserva;
    }
    // Asignar valores base
    if (problema.steps[0].valores && problema.steps[0].valores.length > 0) problema.steps[0].valores[0] = problema.valor1;
    if (problema.steps1[0].valores && problema.steps1[0].valores.length > 0) problema.steps1[0].valores[0] = problema.valor1;
    if (problema.steps2[0].valores && problema.steps2[0].valores.length > 0) problema.steps2[0].valores[0] = problema.valor1;
    if (problema.steps3[0].valores && problema.steps3[0].valores.length > 0) problema.steps3[0].valores[0] = problema.valor1;
    problema.steps[0].multiplicador = problema.valor2;
    problema.steps1[0].multiplicador = [problema.valor2[2]];
    problema.steps2[0].multiplicador = [problema.valor2[1]];
    problema.steps3[0].multiplicador = [problema.valor2[0]];
    // Steps de suma
    // Suma de unidades
    aux = problema.steps1[1].resultado;
    problema.steps[16].resultado = aux;
    problema.final.resultado[6] = this.obtenerDigito(aux, 0);
    // Suma de decenas
    aux = problema.steps1[2].resultado + problema.steps2[1].resultado;
    problema.steps[17].resultado = this.obtenerDigito(aux, 0);
    problema.steps[17].reserva = this.obtenerDigito(aux, 1);
    problema.final.resultado[5] = this.obtenerDigito(aux, 0);
    // Suma de centenas
    aux = problema.steps1[3].resultado + problema.steps2[2].resultado + problema.steps3[1].resultado + problema.steps[17].reserva;
    problema.steps[18].resultado = this.obtenerDigito(aux, 0);
    problema.steps[18].reserva = this.obtenerDigito(aux, 1);
    problema.final.resultado[4] = this.obtenerDigito(aux, 0);
    // Suma de unidades de mil
    aux = problema.steps1[4].resultado + problema.steps2[3].resultado + problema.steps3[2].resultado + problema.steps[18].reserva;
    problema.steps[19].resultado = this.obtenerDigito(aux, 0);
    problema.steps[19].reserva = this.obtenerDigito(aux, 1);
    problema.final.resultado[3] = this.obtenerDigito(aux, 0);
    // Suma de decenas de mil
    aux = problema.steps1[5].resultado + problema.steps2[4].resultado + problema.steps3[3].resultado + problema.steps[19].reserva;
    problema.steps[20].resultado = this.obtenerDigito(aux, 0);
    problema.steps[20].reserva = this.obtenerDigito(aux, 1);
    problema.final.resultado[2] = this.obtenerDigito(aux, 0);
    // Suma de centenas de mil
    aux = problema.steps2[5].resultado + problema.steps3[4].resultado + problema.steps[20].reserva;
    problema.steps[21].resultado = this.obtenerDigito(aux, 0);
    problema.steps[21].reserva = this.obtenerDigito(aux, 1);
    problema.final.resultado[1] = this.obtenerDigito(aux, 0);
    // Suma de unidaes de millon
    aux = problema.steps3[5].resultado + problema.steps[21].reserva;
    problema.steps[22].resultado = this.obtenerDigito(aux, 0);
    problema.final.resultado[0] = this.obtenerDigito(aux, 0);
    return problema;
  }

  generarNivel65() {
    let problema = this.generarNivel6();
    problema.steps = [problema.steps[problema.steps.length - 1]];
    return problema;
  }

  obtenerDigito(numero: number, digito: number = 0): number{
    if(!numero && numero != 0) throw new Error("No hay un número");
    let numeroStr = numero.toString();
    if (digito + 1 > numeroStr.length) return 0;
    let result = numero.toString()[numeroStr.length - (digito + 1)];
    if(!result) return 0;
    return parseInt(result);
  }

  generarNivel(nivel: number, not: number[] = []) {
    let niveles = [2, 2, 2, 3, 3, 4, 4, 5, 5, 6, 6, 7, 7, 8, 8, 9, 9, 10, 10];
    let problema: any = {
      valor1: 0,
      valor2: 0,
      resultado: 0,
      steps: [],
      stepsCols: [],
      stepsRows: []
    }
    let a = niveles[nivel];
    let x = Math.floor(Math.random() * 8) + 2;
    if (not.includes(x)) x = Math.floor(Math.random() * 8) + 2;
    // a = 7;
    problema.valor1 = a;
    problema.valor2 = x;
    problema.resultado = a * x;

    for (let index = 1; index <= x; index++) {
      problema.steps.push(index * a);
      problema.stepsCols.push(a);
      problema.stepsRows.push(index - 1);
    }

    return problema;
  }

  generarNivelBase(valor1: number, valor2: number) {
    let niveles = [2, 2, 2, 3, 3, 4, 4, 5, 5, 6, 6, 7, 7, 8, 8, 9, 9, 10, 10];
    let problema: any = {
      valor1: 0,
      valor2: 0,
      resultado: 0,
      steps: [],
      stepsCols: [],
      stepsRows: []
    }
    let a = valor1;
    let x = valor2;
    // a = 7;
    problema.valor1 = a;
    problema.valor2 = x;
    problema.resultado = a * x;

    for (let index = 1; index <= x; index++) {
      problema.steps.push(index * a);
      problema.stepsCols.push(a);
      problema.stepsRows.push(index - 1);
    }

    return problema;
  }
}
