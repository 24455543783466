<div class="bg-white rounded">
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">Ventana de Correción</h4>
            <button type="button" class="btn-close btn-close-white" aria-label="Close"
                (click)="activeModal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
            <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                <li [ngbNavItem]="1">
                    <a ngbNavLink>Respuesta Correcta</a>
                    <ng-template ngbNavContent>
                        <div class="work-area h-100 w-100 d-flex justify-content-evenly p-3" [ngClass]="{'big': big}">
                            <div class="grid">
                                <div class="encabezados-mil" *ngIf="config.reserva.length > 3">
                                    <div class="cell only-child" *ngIf="config.reserva.length > 6">MILLÓN<div
                                            class="bubble"></div>
                                    </div>
                                    <ng-container *ngFor="let col of config.reserva; let index = index">
                                        <div class="cell" *ngIf="index > 2 && index < 6"
                                            [ngClass]="{'only-child': config.reserva.length == 4, 'first-child': config.reserva.length > 6 && index == 3}">
                                            {{config.reserva.length > 6 && index == 4 ? 'MIL' : ''}}<div class="bubble">
                                            </div>
                                        </div>
                                    </ng-container>
                                    <p class="overlay" *ngIf="config.reserva.length < 7">MIL</p>
                                </div>
                                <div class="encabezados">
                                    <div class="cell centena" *ngFor="let col of config.reserva; let index = index;">
                                        {{encabezados[config.reserva.length
                                        - (index+1)]}}<div class="bubble" *ngIf="config.reserva.length > 3"></div>
                                    </div>
                                </div>
                                <div class="reserva">
                                    <div class="cell valor" *ngFor="let valor of config.reserva; let index = index;">
                                        <div class="area">
                                            <div class="white-cell">
                                                <input type="text" mask="0" [id]="'reserva' + index" readonly
                                                    *ngIf="valor != 'disabled' && reserva[index] == 0"
                                                    autocomplete="off">
                                                <input type="text" mask="0" [id]="'reserva' + index"
                                                    [(ngModel)]="reserva[index]" readonly
                                                    *ngIf="valor != 'disabled' && reserva[index] != 0"
                                                    autocomplete="off">
                                                <input type="text" mask="0" disabled *ngIf="valor == 'disabled'"
                                                    autocomplete="off">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="valores" *ngFor="let row of config.valores; let rowIndex = index">
                                    <ng-container *ngIf="rowIndex > 0 || multiplicador.length > 0">
                                        <div class="cell valor" *ngFor="let valor of row; let index = index">
                                            <div class="area">
                                                <input type="text" mask="0" [(ngModel)]="valores[rowIndex][index]"
                                                    *ngIf="valor != 'disabled'" readonly autocomplete="off">
                                                <input type="text" mask="0" disabled *ngIf="valor == 'disabled'"
                                                    autocomplete="off">
                                            </div>
                                            <div class="h-line-1" *ngIf="rowIndex == 1"></div>
                                            <div class="v-line" *ngIf="nivel == 1"></div>

                                            <div class="h-line-2" *ngIf="rowIndex == ((problema.valor2-1)/2)+1"></div>
                                            <div class="line-text" *ngIf="rowIndex == ((problema.valor2-1)/2)+1">{{problema.valor2[0]}} veces</div>
                                            
                                            <div class="h-line-p" *ngIf="((problema.valor2[0]) % 2) == 0 && rowIndex == ((problema.valor2[0])/2)+1">
                                            </div>
                                            <div class="line-text-p" *ngIf="((problema.valor2[0]) % 2) == 0 && rowIndex == ((problema.valor2[0])/2)+1">{{problema.valor2[0]}} veces</div>
                                            
                                            <div class="h-line-3" *ngIf="rowIndex == problema.valor2"></div>
                                        </div>
                                    </ng-container>
                                    <div class="cell multiplicacion" *ngIf="rowIndex == 0 && multiplicador.length > 0">
                                        <div class="area">
                                            <div><i><img style="height: 50px; width: 50px;"
                                                        src="./assets/templates/dot.svg" alt=""></i>
                                            </div>
                                        </div>
                                    </div>
                                    <ng-container *ngIf="rowIndex == 0 && multiplicador.length > 0">
                                        <div class="cell multiplicando"
                                            *ngFor="let valor of config.multiplicador; let index = index">
                                            <div class="area">
                                                <input type="text" mask="0" [(ngModel)]="multiplicador[index]"
                                                    *ngIf="valor != 'disabled'" readonly autocomplete="off">
                                                <input type="text" mask="0" disabled *ngIf="valor == 'disabled'"
                                                    autocomplete="off">
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>


                                <div class="resultado">
                                    <div class="cell" *ngFor="let valor of config.resultado; let index = index">
                                        <div class="area">
                                            <input type="text" mask="0" [(ngModel)]="resultado[index]"
                                                *ngIf="valor != 'disabled'" readonly autocomplete="off">
                                            <input type="text" mask="0" disabled *ngIf="valor == 'disabled'"
                                                autocomplete="off">
                                        </div>
                                    </div>
                                </div>
                                <div class="separador" *ngIf="multiplicador.length > 0 && config.valores.length > 0">
                                    <div class="cell extra-i"></div>
                                    <div class="cell centena" *ngFor="let valor of config.resultado; let index = index">
                                    </div>
                                    <div class="cell extra-d"></div>
                                </div>
                                <div class="separador"
                                    [ngClass]="'separador-' + (multiplicador.length > 0 ? config.valores.length : config.valores.length-1)"
                                    *ngIf="multiplicador.length == 0">
                                    <div class="cell extra-i"></div>
                                    <div class="cell centena" *ngFor="let valor of config.resultado; let index = index">
                                    </div>
                                    <div class="cell extra-d"></div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="2">
                    <a ngbNavLink>Tu Respuesta</a>
                    <ng-template ngbNavContent>
                        <div class="work-area h-100 w-100 d-flex justify-content-evenly p-3" [ngClass]="{'big': big}">
                            <div class="grid">
                                <div class="encabezados-mil" *ngIf="config.reserva.length > 3">
                                    <div class="cell only-child" *ngIf="config.reserva.length > 6">MILLÓN<div
                                            class="bubble"></div>
                                    </div>
                                    <ng-container *ngFor="let col of config.reserva; let index = index">
                                        <div class="cell" *ngIf="index > 2 && index < 6"
                                            [ngClass]="{'only-child': config.reserva.length == 4, 'first-child': config.reserva.length > 6 && index == 3}">
                                            {{config.reserva.length > 6 && index == 4 ? 'MIL' : ''}}<div class="bubble">
                                            </div>
                                        </div>
                                    </ng-container>
                                    <p class="overlay" *ngIf="config.reserva.length < 7">MIL</p>
                                </div>
                                <div class="encabezados">
                                    <div class="cell centena" *ngFor="let col of config.reserva; let index = index;">
                                        {{encabezados[config.reserva.length - (index+1)]}}<div class="bubble"
                                            *ngIf="config.reserva.length > 3"></div>
                                    </div>
                                </div>
                                <div class="reserva">
                                    <div class="cell valor" *ngFor="let valor of config.reserva; let index = index;">
                                        <div class="area">
                                            <div class="white-cell">
                                                <input type="text" mask="0" [id]="'reserva' + index"
                                                    [(ngModel)]="miReserva[index]" readonly *ngIf="valor != 'disabled'"
                                                    autocomplete="off">
                                                <input type="text" mask="0" disabled *ngIf="valor == 'disabled'"
                                                    autocomplete="off">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="valores" *ngFor="let row of config.valores; let rowIndex = index">
                                    <ng-container *ngIf="rowIndex > 0 || multiplicador.length > 0">
                                        <div class="cell valor" *ngFor="let valor of row; let index = index">
                                            <div class="area">
                                                <input type="text" mask="0" [(ngModel)]="misValores[rowIndex][index]"
                                                    *ngIf="valor != 'disabled'" readonly autocomplete="off">
                                                <input type="text" mask="0" disabled *ngIf="valor == 'disabled'"
                                                    autocomplete="off">
                                            </div>
                                            <div class="h-line-1" *ngIf="rowIndex == 1"></div>
                                            <div class="v-line" *ngIf="nivel == 1"></div>

                                            <div class="h-line-2" *ngIf="rowIndex == ((problema.valor2-1)/2)+1"></div>
                                            <div class="line-text" *ngIf="rowIndex == ((problema.valor2-1)/2)+1">{{problema.valor2[0]}} veces</div>
                                            
                                            <div class="h-line-p" *ngIf="((problema.valor2[0]) % 2) == 0 && rowIndex == ((problema.valor2[0])/2)+1">
                                            </div>
                                            <div class="line-text-p" *ngIf="((problema.valor2[0]) % 2) == 0 && rowIndex == ((problema.valor2[0])/2)+1">{{problema.valor2[0]}} veces</div>
                                            
                                            <div class="h-line-3" *ngIf="rowIndex == problema.valor2"></div>
                                        </div>
                                    </ng-container>
                                    <div class="cell multiplicacion" *ngIf="rowIndex == 0 && multiplicador.length > 0">
                                        <div class="area">
                                            <div><i><img style="height: 50px; width: 50px;"
                                                        src="./assets/templates/dot.svg" alt=""></i>
                                            </div>
                                        </div>
                                    </div>
                                    <ng-container *ngIf="rowIndex == 0 && multiplicador.length > 0">
                                        <div class="cell multiplicando"
                                            *ngFor="let valor of config.multiplicador; let index = index">
                                            <div class="area">
                                                <input type="text" mask="0" [(ngModel)]="miMultiplicador[index]"
                                                    *ngIf="valor != 'disabled'" readonly autocomplete="off">
                                                <input type="text" mask="0" disabled *ngIf="valor == 'disabled'"
                                                    autocomplete="off">
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>


                                <div class="resultado">
                                    <div class="cell" *ngFor="let valor of config.resultado; let index = index">
                                        <div class="area">
                                            <input type="text" mask="0" [(ngModel)]="miResultado[index]"
                                                *ngIf="valor != 'disabled'" readonly autocomplete="off">
                                            <input type="text" mask="0" disabled *ngIf="valor == 'disabled'"
                                                autocomplete="off">
                                        </div>
                                    </div>
                                </div>
                                <div class="separador" *ngIf="multiplicador.length > 0 && config.valores.length > 0">
                                    <div class="cell extra-i"></div>
                                    <div class="cell centena" *ngFor="let valor of config.resultado; let index = index">
                                    </div>
                                    <div class="cell extra-d"></div>
                                </div>
                                <div class="separador"
                                    [ngClass]="'separador-' + (multiplicador.length > 0 ? config.valores.length : config.valores.length-1)"
                                    *ngIf="multiplicador.length == 0">
                                    <div class="cell extra-i"></div>
                                    <div class="cell centena" *ngFor="let valor of config.resultado; let index = index">
                                    </div>
                                    <div class="cell extra-d"></div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="nav" class="p-3"></div>
        </div>
        <div class="modal-footer btns">
            <!-- <button type="button" class="btn btn-success" (click)="guardar()">Volver a intentar</button> -->
            <button type="button" class="btn btn-listo" (click)="activeModal.dismiss('Cancel click')">Revisado</button>
        </div>
    </div>
</div>