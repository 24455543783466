import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import html2canvas from 'html2canvas';
import { LevelManagerService } from 'src/app/services/level-manager.service';
import { LikeManagerService } from 'src/app/services/like-manager.service';
import { ProblemGeneratorService } from 'src/app/services/problem-generator.service';
import { ResultService } from 'src/app/services/result.service';
import { ErrorResultadoComponent } from '../../error-resultado/error-resultado.component';
import { ErrorComponent } from '../../error/error.component';
import { InstruccionComponent } from '../../instruccion/instruccion.component';
import { QuestionComponent } from '../../question/question.component';
import { TablaSelectorComponent } from '../../tabla-selector/tabla-selector.component';

@Component({
  selector: 'app-nivel65',
  templateUrl: './nivel65.component.html',
  styleUrls: ['./nivel65.component.scss']
})
export class Nivel65Component implements OnInit {
  resultados: any[] = [];
  final: string = '';
  finalFinal: string = '';

  problema: any = {};
  entrenamiento = true;
  encabezados: string[] = ['U', 'D', 'C', 'U', 'D', 'C', 'U']

  step: number = 1;

  config: any = {
    valores: [
      ['disabled', 'disabled', 'disabled', null, null, null, null],
      ['disabled', 'disabled', 'readonly', 'readonly', 'readonly', 'readonly', 'readonly'],
      ['disabled', 'readonly', 'readonly', 'readonly', 'readonly', 'readonly', 'disabled'],
      ['readonly', 'readonly', 'readonly', 'readonly', 'readonly', 'disabled', 'disabled']
    ],
    multiplicador: [null, null, null],
    reserva: ['readonly', 'readonly', 'readonly', 'readonly', 'readonly', 'disabled', 'disabled'],
    resultado: ['readonly', 'readonly', 'readonly', 'readonly', 'readonly', 'readonly', 'readonly']
  };

  working: boolean = false;
  stepsResults: boolean[] = new Array(20).fill(true);

  isMobile = () => {
    let response = false;
    response = response || /IEMobile/i.test(navigator.userAgent);
    response = response || /Android/i.test(navigator.userAgent);
    response = response || /BlackBerry/i.test(navigator.userAgent);
    response = response || /iPhone|iPad|iPod/i.test(navigator.userAgent);
    return response
  };

  constructor(
    private generator: ProblemGeneratorService,
    private modalService: NgbModal,
    public result: ResultService,
    public likeManager: LikeManagerService,
    private levelManager: LevelManagerService
  ) { }

  ngOnInit(): void {
    this.problema = this.generator.generarNivel65();

    if (this.entrenamiento) {
      this.mostrarInformacion()
    }
  }

  capturar() {
    return new Promise((resolve, reject) => {
        let final = '' + this.final;
        let imagenes = ['', ''];
        this.finalFinal = final;
        setTimeout(() => {
          html2canvas(<any>document.querySelector("#grid")).then(canvas => {
            const img = canvas.toDataURL('image/png')
            imagenes[0] = img;
            this.finalFinal = '' + this.problema.resultado;
            setTimeout(() => {
              html2canvas(<any>document.querySelector("#grid")).then(canvas => {
                const img = canvas.toDataURL('image/png')
                imagenes[1] = img;
                this.finalFinal = final;
                resolve(imagenes);
              });
            }, 1);
          }).catch((err) => {
            resolve([])
          });
        }, 1);
    })
  }

  isReady(): boolean {
    return !!this.final;
  }

  mostrarInformacion() {
    const modalRef = this.modalService.open(InstruccionComponent, { size: 'lg', centered: !this.isMobile(), backdropClass: 'white' });
    modalRef.componentInstance.instruccion = this.generator.instrucciones.nivel6[23];
    modalRef.result.then((result) => { }, (reason) => { });
  }

  mostrarAyuda() {
    const modalRef = this.modalService.open(TablaSelectorComponent, { size: 'xl', scrollable: true, backdrop: 'static', modalDialogClass: 'modal-ayuda' });
    modalRef.componentInstance.mostrarTabla = false;
    modalRef.componentInstance.entrenamiento = this.entrenamiento;
    modalRef.result.then((result) => {
    }, (reason) => { });
  }

  toggleEntrenamiento() {
    if (!this.result.haveUserId()) return;
    let msg = "¿Estás seguro de que quieres terminar el entrenamiento?";
    if (!this.entrenamiento) {
      msg = "<p>Cambiar a modo de entrenamiento hará que se pierda el progreso actual.</p><p>¿desea continuar?</p>"
    }
    const modalRef = this.modalService.open(QuestionComponent, { size: 'lg', scrollable: true, centered: !this.isMobile() });
    modalRef.componentInstance.mensaje = msg;
    modalRef.result.then((result) => {
      this.entrenamiento = !this.entrenamiento;
      this.result.cleanResults();
      this.restart();
    }, (reason) => { });
  }

  async checkFinalAnswer() {
    if (this.entrenamiento) {
      if (this.final == this.problema.resultado) {
        this.likeManager.showLike();
        this.restart();
      } else {
        if (this.entrenamiento) {
          this.stepsResults[this.step] = true;
          const modalRef = this.modalService.open(ErrorResultadoComponent, { size: 'lg', centered: !this.isMobile() });
          modalRef.componentInstance.problema = this.problema;
          modalRef.componentInstance.miResultado = this.final;
          modalRef.componentInstance.resultado = this.problema.resultado;
          modalRef.result.then((result) => { }, (reason) => { });
        }
      }
    } else {
      // Guardar resultado
      this.resultados.push({
        esperado: {
          resultado: '' + this.problema.resultado
        },
        registrado: {
          resultado: '' + this.final
        }
      })
      let imagenes = await this.capturar();
      this.result.addResult(this.resultados, imagenes);
      if (this.result.results.length == 7) {
        this.levelManager.showNextLevel();
      } else {
        this.restart();
      }
    }
  }

  listo() {
    this.working = true;
    if (!this.stepsResults[this.step]) return this.working = false;
    this.stepsResults[this.step] = false;
    this.checkFinalAnswer();
    return this.working = false;
  }

  restart() {
    this.step = 1;
    this.problema = this.generator.generarNivel65();
    this.final = '';
    this.stepsResults = new Array(20).fill(true);

    this.config = {
      valores: [
        ['disabled', 'disabled', 'disabled', null, null, null, null],
        ['disabled', 'disabled', 'readonly', 'readonly', 'readonly', 'readonly', 'readonly'],
        ['disabled', 'readonly', 'readonly', 'readonly', 'readonly', 'readonly', 'disabled'],
        ['readonly', 'readonly', 'readonly', 'readonly', 'readonly', 'disabled', 'disabled']
      ],
      multiplicador: [null, null, null],
      reserva: ['readonly', 'readonly', 'readonly', 'readonly', 'readonly', 'disabled', 'disabled'],
      resultado: ['readonly', 'readonly', 'readonly', 'readonly', 'readonly', 'readonly', 'readonly']
    };
    this.resultados = [];
    if (this.entrenamiento) {
      this.mostrarInformacion();
    }
  }

}
