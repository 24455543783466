import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormatterService } from 'src/app/utils/formatter/formatter.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  active = 2;

  @Input() nivel: number = 2;
  @Input() problema: any = {};
  @Input() big: boolean = false;
  @Input() suma: boolean = false;

  @Input() reserva: any = [];
  @Input() valores: any = [[]];
  @Input() resultado: any = [];
  @Input() multiplicador: any = [];
  @Input() config = {
    valores: [['disabled', null, null]], multiplicador: [null], reserva: [null, null, 'disabled'], resultado: [null, null, null]
  };

  encabezados: string[] = ['U', 'D', 'C', 'U', 'D', 'C', 'U']

  @Input() miReserva: any = [];
  @Input() misValores: any = [[]];
  @Input() miResultado: any = [];
  @Input() miMultiplicador: any = [];
  @Input() miConfig = {
    valores: [['disabled', null, null]], multiplicador: [null], reserva: [null, null, 'disabled'], resultado: [null, null, null]
  };

  constructor(
    public activeModal: NgbActiveModal,
    public formatter: FormatterService
  ) {}
  
  ngOnInit() {
  }
  
  guardar(){
  }

}
