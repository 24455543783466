import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import html2canvas from 'html2canvas';
import { LevelManagerService } from 'src/app/services/level-manager.service';
import { LikeManagerService } from 'src/app/services/like-manager.service';
import { ProblemGeneratorService } from 'src/app/services/problem-generator.service';
import { ResultService } from 'src/app/services/result.service';
import { ErrorResultadoComponent } from '../../error-resultado/error-resultado.component';
import { ErrorComponent } from '../../error/error.component';
import { InstruccionComponent } from '../../instruccion/instruccion.component';
import { QuestionComponent } from '../../question/question.component';
import { TablaSelectorComponent } from '../../tabla-selector/tabla-selector.component';

@Component({
  selector: 'app-nivel1',
  templateUrl: './nivel1.component.html',
  styleUrls: ['./nivel1.component.scss'],
  
})
export class Nivel1Component implements OnInit {
  valoresFinal: any = [[]];
  multiplicadorFinal: any = [];
  resultadoFinal: any = [];
  reservaFinal: any = [];
  finalFinal: any = '';

  resultados: any[] = [];
  final: string = '';
  problema: any = {};
  entrenamiento = true;

  reserva: any = ['',''];
  valores: any = [[]];
  resultado: any = [];
  multiplicador: any = [];

  step: number = 0;
  secuencia: number = 1;

  config: any = {
    valores: [
      ['disabled', null, null],
      ['disabled', null, null],
      ['disabled', null, null],
      ['disabled', null, null],
      ['disabled', null, null],
      ['disabled', null, null],
      ['disabled', null, null],
      ['disabled', null, null],
      ['disabled', null, null],
    ], 
    multiplicador: [''], 
    reserva: ['readonly', 'readonly', 'disabled'], 
    resultado: ['readonly', 'readonly', 'readonly']
  };
  config1: any = {
    valores: [
      ['disabled', null, null]
    ],
    multiplicador: [''],
    reserva: ['readonly', 'readonly', 'disabled'],
    resultado: ['readonly', 'readonly', 'readonly']
  };

  working: boolean = false;
  stepsResults: boolean[] = new Array(20).fill(true);

  isMobile = () => {
    let response = false;
    response = response || /IEMobile/i.test(navigator.userAgent);
    response = response || /Android/i.test(navigator.userAgent);
    response = response || /BlackBerry/i.test(navigator.userAgent);
    response = response || /iPhone|iPad|iPod/i.test(navigator.userAgent);
    return response
  };

  constructor(
    private generator: ProblemGeneratorService,
    private modalService: NgbModal,
    public result: ResultService,
    public likeManager: LikeManagerService,
    private levelManager: LevelManagerService
  ) { }

  ngOnInit(): void {
    this.problema = this.generator.generarNivel1();
    this.valores = new Array(9).fill([]);
    // this.config.valores = new Array(this.problema.valor2[0]).fill(['disabled', null, null]);
    if (this.entrenamiento) {
      this.mostrarInformacion();
    }
    // this.step = 3;
  }

  capturar() {
    return new Promise((resolve, reject) => {
      let valores = Object.assign({}, this.valores);
      let multiplicador = Object.assign({}, this.multiplicador);
      let resultado = Object.assign({}, this.resultado);
      let reserva = Object.assign({}, this.reserva);
      let imagenes = ['', ''];
      let final = '' + this.final;
      
      this.valoresFinal = valores;
      this.multiplicadorFinal = multiplicador;
      this.resultadoFinal = resultado;
      this.reservaFinal = reserva;
      this.finalFinal = final;
      setTimeout(() => {
          html2canvas(<any>document.querySelector("#grid")).then(canvas => {
            const img = canvas.toDataURL('image/png')
            imagenes[0] = img;
            this.valoresFinal = this.problema.final.valores;
            this.multiplicadorFinal = this.problema.final.multiplicador;
            this.resultadoFinal = this.problema.final.resultado;
            this.reservaFinal = this.problema.final.reserva;
            this.finalFinal = this.problema.resultado;
            setTimeout(() => {
              html2canvas(<any>document.querySelector("#grid")).then(canvas => {
                const img = canvas.toDataURL('image/png')
                imagenes[1] = img;
                this.valoresFinal = valores;
                this.multiplicadorFinal = multiplicador;
                this.resultadoFinal = resultado;
                this.reservaFinal = reserva;
                this.finalFinal = final;
                resolve(imagenes)
              });
            }, 1);
          }).catch( (err) => {
            resolve([])
          });
        }, 1);
    })
  }

  mostrarInformacion(){
    const modalRef = this.modalService.open(InstruccionComponent, { size: 'lg', centered: !this.isMobile(), backdropClass: 'white', backdrop: this.generator.instrucciones.nivel1[this.step].split('$split').length > 1 ? 'static' : true });
    modalRef.componentInstance.instruccion = this.generator.instrucciones.nivel1[this.step];
    modalRef.result.then((result) => { }, (reason) => { });
  }

  mostrarAyuda(){
    let index1 = this.valores[0][3- this.step];
    let index2 = this.multiplicador[0] -0;
    const modalRef = this.modalService.open(TablaSelectorComponent, { size: 'xl', scrollable: true, centered: !this.isMobile(), backdrop: 'static', modalDialogClass: 'modal-ayuda' });
    modalRef.componentInstance.problema = this.generator.generarNivelBase(index2, index1);
    modalRef.componentInstance.entrenamiento = this.entrenamiento;
    modalRef.result.then((result) => {
      // let indexReserva = this.config.reserva.findIndex((valor: any) => valor != 'disabled' && valor != 'readonly');
      // let indexResultado = this.config.resultado.findIndex((valor: any) => valor != 'disabled' && valor != 'readonly');
      // this.resultado[indexResultado] = (result.resultado-0) + (this.reserva[indexReserva + 1] ? (this.reserva[indexReserva+1]-0) : 0) - 0;
      // this.reserva[indexReserva] = result.reserva;
      // if (this.resultado[indexResultado] >= 10) {
      //   this.resultado[indexResultado] %= 10;
      //   this.reserva[indexReserva] -= -1;
      // }
    }, (reason) => { });
  }

  toggleEntrenamiento() {
    if(!this.result.haveUserId()) return;
    let msg = "¿Estás seguro de que quieres terminar el entrenamiento?";
    if (!this.entrenamiento) {
      msg = "<p>Cambiar a modo de entrenamiento hará que se pierda el progreso actual.</p><p>¿desea continuar?</p>"
    }
    const modalRef = this.modalService.open(QuestionComponent, { size: 'lg', scrollable: true, centered: !this.isMobile() });
    modalRef.componentInstance.mensaje = msg;
    modalRef.result.then((result) => {
      this.entrenamiento = !this.entrenamiento;
      this.result.cleanResults();
      this.restart();
    }, (reason) => { });
  }

  isReady(): boolean{
    if(this.step == 0) {
      // return this.valores[0].length + 1 == this.problema.steps[0].valores.length && this.multiplicador == this.problema.steps[0].multiplicador.length;
      return !!this.valores[0][1] && !!this.valores[0][2];
    } else if (this.step < this.problema.steps.length) {
      let step = this.step;
      let result = true;
      if (step + 1 <= this.config.reserva.length) result = result && !!this.reserva[this.reserva.length - (step)];
      if (!this.problema.steps[this.step].reserva) result = true;
      if (!this.entrenamiento) result = true;
      result = result && !!this.resultado[this.resultado.length - (step)];
      return result
    } else {
      return !!this.final;
    }
    return true
  }

  async checkFinalAnswer() {
    if (this.entrenamiento) {
      if (this.final == this.problema.resultado) {
        this.likeManager.showLike();
        this.restart()
      } else {
        if (this.entrenamiento) {
          this.stepsResults[this.step] = true;
          const modalRef = this.modalService.open(ErrorResultadoComponent, { size: 'lg', centered: !this.isMobile() });
          modalRef.componentInstance.problema = this.problema;
          modalRef.componentInstance.miResultado = this.final;
          modalRef.componentInstance.resultado = this.problema.resultado;
          modalRef.result.then((result) => { }, (reason) => { });
        }
      }
    } else {
      // Guardar resultado
      this.resultados.push({
        esperado: {
          resultado: '' + this.problema.resultado
        },
        registrado: {
          resultado: '' + this.final
        }
      })
      let imagenes = await this.capturar();
      this.result.addResult(this.resultados, imagenes);
      if(this.result.results.length == 7) {
        this.levelManager.showNextLevel();
      } else {
        this.restart();
      }
    }
  }

  checkFirstStep(){
    let result = true;
    this.problema.steps[this.step].valores.forEach((row: number[], rowIndex: number) => {
      row.forEach((value: number, index: number) => {
        result = result && value == this.valores[rowIndex][index + 1];
      });
    });
    result = result && this.multiplicador[0] == this.problema.valor2[0];
    // Guardar resultado
    this.resultados.push({
      esperado: {
        factor1: this.problema.steps[0].valores[0].join(''),
        factor2: this.problema.valor2[0] ? '' + this.problema.valor2[0] : ''
      }, 
      registrado: {
        factor1: this.valores[0].join(''),
        factor2: this.multiplicador[0] ? '' + this.multiplicador[0] : ''
      }, 
    })
    // Aumentar el pago
    this.step++;
    if (!result && this.entrenamiento) { // Erro
      const modalRef = this.modalService.open(ErrorComponent, { size: 'lg', centered: !this.isMobile(), backdrop: 'static' });
      modalRef.componentInstance.problema = this.problema;
      modalRef.componentInstance.valores = [['disabled', ...this.problema.steps[0].valores[0]]];
      modalRef.componentInstance.reserva = this.problema.steps.filter((e: any, index: number) => e.reserva && this.step > index).map((e: any) => e.reserva).concat(new Array(this.config.reserva.length - 1).fill(null)).slice(0, this.config.reserva.length - 1).reverse();
      modalRef.componentInstance.resultado = this.problema.steps.filter((e: any, index: number) => e.resultado && this.step > index).map((e: any) => e.resultado).concat(new Array(this.config.reserva.length).fill(null)).slice(0, this.config.reserva.length).reverse();
      modalRef.componentInstance.multiplicador = this.problema.steps[0].multiplicador;
      modalRef.componentInstance.config = this.config1;
      modalRef.componentInstance.miReserva = this.reserva;
      modalRef.componentInstance.misValores = [this.valores[0]];
      modalRef.componentInstance.miResultado = this.resultado;
      modalRef.componentInstance.miConfig = this.config1;
      modalRef.componentInstance.miMultiplicador = this.multiplicador;
      modalRef.result.then((result) => { }, (reason) => { });
      this.step--;
      this.stepsResults[this.step] = true;
    } else { // Sin error
      let step = this.step - 1;
      // Habilitar siguientes formularios
      if (step < this.problema.steps.length) {
        if (step <= this.config.reserva.length) this.config.reserva[this.config.reserva.length - (step + 2)] = '';
        this.config.resultado[this.config.resultado.length - (step + 1)] = '';
      }
      // Deshabilitar valores formularios anteriores
      if (step < this.problema.steps.length) {
        if (step == 0) {
          this.config.valores = this.config.valores.map((row: any) => row.map((e: any) => (e == 'disabled') ? 'disabled' : 'readonly'));
          this.config.multiplicador = this.config.multiplicador.map((e: any) => (e == 'disabled') ? 'disabled' : 'readonly');
        } else {
          if (step <= this.config.reserva.length) this.config.reserva[this.config.reserva.length - (step + 1)] = 'readonly';
          this.config.resultado[this.config.resultado.length - (step)] = 'readonly';
        }
      }
      // Mostrar información
      if (this.entrenamiento) {
        this.likeManager.showLike();
        this.mostrarInformacion();
      }
    }
  }

  checkStep(){
    let result = true;
    if (this.step <= this.reserva.length) {
      result = result && ((!this.problema.steps[this.step].reserva && !this.reserva[this.reserva.length - this.step]) || this.problema.steps[this.step].reserva == this.reserva[this.reserva.length - this.step]);
      result = result && this.problema.steps[this.step].resultado == this.resultado[this.resultado.length - this.step];
    } else {
      result = result && (!this.problema.steps[this.step].resultado && !this.resultado[this.resultado.length - this.step]) || this.problema.steps[this.step].resultado == this.resultado[this.resultado.length - this.step];
    }
    // Guardar resultado
    this.resultados.push({
      esperado: {
        producto: this.problema.steps[this.step].resultado ? '' + this.problema.steps[this.step].resultado : '',
        reserva: this.problema.steps[this.step].reserva ? '' + this.problema.steps[this.step].reserva : ''
      },
      registrado: {
        producto: this.resultado[this.resultado.length - this.step] ? '' + this.resultado[this.resultado.length - this.step] : '',
        reserva: this.reserva[this.reserva.length - this.step] ? '' + this.reserva[this.reserva.length - this.step] : ''
      }
    })
    // Siguiente paso
    this.step++;
    if (!result && this.entrenamiento) {
      const modalRef = this.modalService.open(ErrorComponent, { size: 'lg', centered: !this.isMobile(), backdrop: 'static' });
      modalRef.componentInstance.nivel = 1;
      modalRef.componentInstance.problema = this.problema;
      modalRef.componentInstance.valores = new Array(this.multiplicador[0]-0+1).fill(['disabled', ...this.problema.valor1]);
      modalRef.componentInstance.reserva = this.problema.steps.filter((e: any, index: number) => e.reserva && this.step > index).map((e: any) => e.reserva).concat(new Array(this.config.reserva.length - 1).fill(null)).slice(0, this.config.reserva.length - 1).reverse();
      modalRef.componentInstance.resultado = this.problema.steps.filter((e: any, index: number) => (e.resultado || e.resultado == 0) && this.step > index).map((e: any) => e.resultado).concat(new Array(this.config.reserva.length).fill(null)).slice(0, this.config.reserva.length).reverse();
      modalRef.componentInstance.multiplicador = [];
      let config = Object.assign({}, this.config);
      config.valores = new Array(this.multiplicador[0]-0+1).fill(config.valores[0]);
      modalRef.componentInstance.config = config;
      modalRef.componentInstance.miReserva = this.reserva;
      let valores = Object.assign({}, this.valores);
      valores = [this.valores[0], ...this.valores];
      modalRef.componentInstance.misValores = valores;
      modalRef.componentInstance.miResultado = this.resultado;
      modalRef.componentInstance.miConfig = this.config;
      modalRef.componentInstance.miMultiplicador = [];
      modalRef.result.then((result) => { }, (reason) => { });
      this.step--;
      this.stepsResults[this.step] = true;
    } else {
      let step = this.step - 1;
      // Habilitar siguientes formularios
      if (step < this.problema.steps.length) {
        if (step <= this.config.reserva.length) this.config.reserva[this.config.reserva.length - (step + 2)] = '';
        this.config.resultado[this.config.resultado.length - (step + 1)] = '';
      }
      // Deshabilitar valores formularios anteriores
      if (step < this.problema.steps.length) {
        if (step == 0) {
          this.config.valores = this.config.valores.map((row: any) => row.map((e: any) => (e == 'disabled') ? 'disabled' : 'readonly'));
          this.config.multiplicador = this.config.multiplicador.map((e: any) => (e == 'disabled') ? 'disabled' : 'readonly');
        } else {
          if (step <= this.config.reserva.length) this.config.reserva[this.config.reserva.length - (step + 1)] = 'readonly';
          this.config.resultado[this.config.resultado.length - (step)] = 'readonly';
        }
      }
      // Saltarse el último paso
      if (this.step == this.problema.steps.length - 1 && !this.reserva[0]) {
        this.listo();
        return;
      }

      if (this.entrenamiento) {
        this.likeManager.showLike();
        this.mostrarInformacion();
      }
    }
  }

  listo() {
    this.working = true;
    if (!this.stepsResults[this.step]) return this.working = false;
    this.stepsResults[this.step] = false;
    if (this.step >= this.problema.steps.length) {
      this.checkFinalAnswer();
      return this.working = false;
    };
    // Comprobar corrección en cada paso
    if (this.step == 0) {
      this.checkFirstStep();
    } else {
      this.checkStep();
    }
    return this.working = false;
  }

  restart() {
    this.step = 0;
    this.secuencia = 1;
    this.problema = this.generator.generarNivel1();
    this.final = '';
    this.reserva = ['',''];
    this.valores = [[]];
    this.resultado = [];
    this.multiplicador = [''];
    this.stepsResults = new Array(20).fill(true);
    this.resultados = [];
    this.config = {
      valores: [
        ['disabled', null, null]
      ],
      multiplicador: [''],
      reserva: ['readonly', 'readonly', 'disabled'],
      resultado: ['readonly', 'readonly', 'readonly']
    };
    this.valores = new Array(9).fill([]);
    this.config.valores = new Array(9).fill(['disabled', null, null]);
    if (this.entrenamiento) {
      this.mostrarInformacion();
    }
  }
}
