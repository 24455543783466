<div class="svg-item">
    <svg width="100%" height="100%" viewBox="0 0 40 40" class="donut">
        <circle class="donut-hole" cx="20" cy="20" r="15.91549430918954" fill="#fff"></circle>
        <circle class="donut-ring" cx="20" cy="20" r="15.91549430918954" fill="transparent" stroke-width="3.5"></circle>
        <circle class="donut-segment donut-segment-2" cx="20" cy="20" r="15.91549430918954" fill="transparent" stroke-width="5" stroke-dasharray="0 100" stroke-dashoffset="25" stroke-linecap="round"></circle>
        <circle *ngIf="step > 0" class="donut-segment donut-segment-2 donut-1" cx="20" cy="20" r="15.91549430918954" fill="transparent" stroke-width="5" stroke-dasharray="14 86" stroke-dashoffset="25" stroke-linecap="round"></circle>
        <circle *ngIf="step > 1" class="donut-segment donut-segment-2 donut-2" cx="20" cy="20" r="15.91549430918954" fill="transparent" stroke-width="5" stroke-dasharray="28 72" stroke-dashoffset="25" stroke-linecap="round"></circle>
        <circle *ngIf="step > 2" class="donut-segment donut-segment-2 donut-3" cx="20" cy="20" r="15.91549430918954" fill="transparent" stroke-width="5" stroke-dasharray="42 58" stroke-dashoffset="25" stroke-linecap="round"></circle>
        <circle *ngIf="step > 3" class="donut-segment donut-segment-2 donut-4" cx="20" cy="20" r="15.91549430918954" fill="transparent" stroke-width="5" stroke-dasharray="56 44" stroke-dashoffset="25" stroke-linecap="round"></circle>
        <circle *ngIf="step > 4" class="donut-segment donut-segment-2 donut-5" cx="20" cy="20" r="15.91549430918954" fill="transparent" stroke-width="5" stroke-dasharray="70 30" stroke-dashoffset="25" stroke-linecap="round"></circle>
        <circle *ngIf="step > 5" class="donut-segment donut-segment-2 donut-6" cx="20" cy="20" r="15.91549430918954" fill="transparent" stroke-width="5" stroke-dasharray="84 16" stroke-dashoffset="25" stroke-linecap="round"></circle>
        <circle *ngIf="step > 6" class="donut-segment donut-segment-2 donut-7" cx="20" cy="20" r="15.91549430918954" fill="transparent" stroke-width="5" stroke-dasharray="100 0" stroke-dashoffset="25" stroke-linecap="round"></circle>

        <g class="donut-text donut-text-1">
            <text y="50%" transform="translate(0, 2)">
                <tspan x="50%" text-anchor="middle" class="donut-percent">{{step+1 == 8 ? 7 : step+1}}/7</tspan>
            </text>
        </g>
    </svg>
</div>