<form id="main-form" action="../../raw.php?view=d&tarea=chki" method="POST" class="d-none">
    <input type="text" id="main-result" name="respuestas">
</form>
<!-- <app-home></app-home> -->
<!-- <app-home *ngIf="!levelManager.anyActive()"></app-home> -->
<app-nivel1 *ngIf="levelManager.show[1]"></app-nivel1>
<app-nivel2 *ngIf="levelManager.show[2]"></app-nivel2>
<app-nivel25 *ngIf="levelManager.show[3]"></app-nivel25>
<app-nivel3 *ngIf="levelManager.show[4]"></app-nivel3>
<app-nivel35 *ngIf="levelManager.show[5]"></app-nivel35>
<app-nivel4 *ngIf="levelManager.show[6]"></app-nivel4>
<app-nivel45 *ngIf="levelManager.show[7]"></app-nivel45>
<app-nivel5 *ngIf="levelManager.show[8]"></app-nivel5>
<app-nivel55 *ngIf="levelManager.show[9]"></app-nivel55>
<app-nivel6 *ngIf="levelManager.show[10]"></app-nivel6>
<app-nivel65 *ngIf="levelManager.show[11]"></app-nivel65>