import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-instruccion',
  templateUrl: './instruccion.component.html',
  styleUrls: ['./instruccion.component.scss']
})
export class InstruccionComponent implements OnInit {

  @Input() instruccion = '';
  @Input() btnListo: boolean = false;
  instrucciones: string[] = [];
  instruccionActiva = '';
  index = 0;
  inicializando = true;

  constructor(
    public activeModal: NgbActiveModal,
  ) {}
  
  ngOnInit() {
    let instruccion = this.instruccion + '';
    if (this.isMobile()) instruccion = instruccion.replace('MUÉVETE', 'DESLIZA TU DEDO');
    else instruccion = instruccion.replace('MUÉVETE', 'MUEVE EL MOUSE');
    this.instrucciones = instruccion.split('${split}');
    this.instruccionActiva = this.instrucciones[0];
    setTimeout(() => {
      this.inicializando = false;
    }, 500);
  }

  next(closeAll: boolean = false) {
    if (this.inicializando) return;
    this.index++;
    if (this.instrucciones[this.index]) this.instruccionActiva = this.instrucciones[this.index];
    else this.activeModal.close(closeAll);
  }

  isMobile = () => {
    let response = false;
    response = response || /IEMobile/i.test(navigator.userAgent);
    response = response || /Android/i.test(navigator.userAgent);
    response = response || /BlackBerry/i.test(navigator.userAgent);
    response = response || /iPhone|iPad|iPod/i.test(navigator.userAgent);
    return response
  };
}
