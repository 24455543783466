import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormatterService } from 'src/app/utils/formatter/formatter.service';

@Component({
  selector: 'app-error-resultado',
  templateUrl: './error-resultado.component.html',
  styleUrls: ['./error-resultado.component.scss']
})
export class ErrorResultadoComponent implements OnInit {

  active = 2;

  @Input() problema: any = {};
  @Input() resultado: any = '';

  @Input() miResultado: any = '';

  constructor(
    public activeModal: NgbActiveModal,
    public formatter: FormatterService
  ) { }

  ngOnInit(): void {
  }

}
