import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ring',
  templateUrl: './ring.component.html',
  styleUrls: ['./ring.component.scss']
})
export class RingComponent implements OnInit {
  @Input() step = 0;

  constructor() { }

  ngOnInit(): void {
  }

}
