import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ResultService {

  results: any[] = [];

  constructor() { }

  cleanResults(){
    this.results = [];
  }

  haveUserId(){
    return (!!this.getVar('user_id') || !!this.getVar('userid'));
  }

  printResult(){
    let result = {
      nivel: this.getVar('n') ? this.getVar('n') : this.getVar('nivel'),
      user_id: this.getVar('user_id'),
      start_time: this.getVar('start_time'),
      actividad_id: this.getVar('intid'),
      respuestas: this.results
    }
  }

  printUrl(){
    var currentUrl = document.referrer;
  }

  addResult(problema: any, imagenes: any){
    this.results.push({pasos: problema, imagenes: imagenes});
    // Si se llega a 7, se envia la respuesta
    if(this.results.length == 7) {
      try {
        let result = {
          nivel: this.getVar('n') ? this.getVar('n') : this.getVar('nivel'),
          user_id: this.getVar('user_id') ? this.getVar('user_id') : this.getVar('userid'),
          start_time: this.getVar('start_time'),
          actividad_id: this.getVar('intid'),
          respuestas: <any[]>[]
        }
        
        result.nivel = this.getVar('n') ? this.getVar('n') : this.getVar('nivel');
        result.user_id = this.getVar('user_id') ? this.getVar('user_id') : this.getVar('userid');
        result.start_time = this.getVar('start_time');
        result.actividad_id = this.getVar('intid');
        // Genera el resultado
        result.respuestas = this.results.map(e => {
          let result: any = {
            imagen1: e.imagenes[0],
            imagen2: e.imagenes[1],
          }
          let index = 1;
          e.pasos.forEach((s: any) => {
            if (s.registrado.factor1){
              result['e' + index] = s.esperado.factor1 != "0" ? s.esperado.factor1 : '';
              result['r' + index] = s.registrado.factor1 != "0" ? s.registrado.factor1 : ''; index++;
              result['e' + index] = s.esperado.factor2 != "0" ? s.esperado.factor2 : '';
              result['r' + index] = s.registrado.factor2 != "0" ? s.registrado.factor2 : ''; index++;
            }
            if (s.registrado.producto || s.registrado.producto == '') {
              result['e' + index] = s.esperado.producto != "0" ? s.esperado.producto : '';
              result['r' + index] = s.registrado.producto != "0" ? s.registrado.producto : ''; index++;
              result['e' + index] = s.esperado.reserva != "0" ? s.esperado.reserva : '';
              result['r' + index] = s.registrado.reserva != "0" ? s.registrado.reserva : ''; index++;
            }
            if (s.registrado.resultado) {
              result['resultado1'] = s.esperado.resultado != "0" ? s.esperado.resultado : '';
              result['resultado2'] = s.registrado.resultado != "0" ? s.registrado.resultado : ''; index++;
            }
          });
          return result;
        });
        // Asigna el resultado al input
        var resultInput: HTMLInputElement | null = <HTMLInputElement>document.getElementById("main-result");
        resultInput.value = btoa(JSON.stringify(result)); // BASE64
        // resultInput.value = JSON.stringify(result); // JSON
        // Ejecuta el envío del formulario
        var form: HTMLFormElement | null = <HTMLFormElement>document.getElementById("main-form");
        if (form) form.submit();
        // Obtener URL actual
        var currentUrl = document.referrer;
        window.parent.postMessage("cerrado", currentUrl);
      } catch (error) {
        this.cleanResults();
      }
    }
  }

  getVar(name: string) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
      results = regex.exec(location.search);
    return results == null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
  }
}
