import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import html2canvas from 'html2canvas';
import { LevelManagerService } from 'src/app/services/level-manager.service';
import { LikeManagerService } from 'src/app/services/like-manager.service';
import { ProblemGeneratorService } from 'src/app/services/problem-generator.service';
import { ResultService } from 'src/app/services/result.service';
import { ErrorResultadoComponent } from '../../error-resultado/error-resultado.component';
import { ErrorComponent } from '../../error/error.component';
import { InstruccionComponent } from '../../instruccion/instruccion.component';
import { QuestionComponent } from '../../question/question.component';
import { TablaSelectorComponent } from '../../tabla-selector/tabla-selector.component';

@Component({
  selector: 'app-nivel5',
  templateUrl: './nivel5.component.html',
  styleUrls: ['./nivel5.component.scss'],
  
})
export class Nivel5Component implements OnInit {
  valoresFinal: any = [[],[],[]];
  resultado1Final: any = [];
  resultado2Final: any = [];
  multiplicadorFinal: any = [];
  resultadoFinal: any = [];
  reservaFinal: any = [];
  finalFinal: any = '';

  resultados: any[] = [];
  final: string = '';

  problema: any = {};
  entrenamiento = true;

  reserva: any = ['','',''];
  valores: any = [[], [], []];
  resultado: any = [];
  multiplicador: any = ['', ''];

  reserva1: any = ['','',''];
  valores1: any = [[]];
  resultado1: any = [];
  multiplicador1: any = [''];

  reserva2: any = ['', '', ''];
  valores2: any = [[]];
  resultado2: any = [];
  multiplicador2: any = [''];

  step: number = 0;
  substep: number = 0;
  secuencia: number = 1;

  config: any = {
    valores: [['disabled', 'disabled', null, null, null], ['disabled', 'readonly', 'readonly', 'readonly', 'readonly'], ['readonly', 'readonly', 'readonly', 'readonly', 'disabled']], multiplicador: [null, null], reserva: ['readonly', 'readonly', 'readonly', 'disabled', 'disabled'], resultado: ['readonly', 'readonly', 'readonly', 'readonly', 'readonly']
  };

  config1: any = {
    valores: [['disabled', null, null, null]], multiplicador: [null], reserva: ['readonly', 'readonly', 'readonly', 'disabled'], resultado: ['readonly', 'readonly', 'readonly', 'readonly']
  };

  config2: any = {
    valores: [['disabled', null, null, null]], multiplicador: [null], reserva: ['readonly', 'readonly', 'readonly', 'disabled'], resultado: ['readonly', 'readonly', 'readonly', 'readonly']
  };

  working: boolean = false;
  stepsResults: boolean[] = new Array(30).fill(true);

  isMobile = () => {
    let response = false;
    response = response || /IEMobile/i.test(navigator.userAgent);
    response = response || /Android/i.test(navigator.userAgent);
    response = response || /BlackBerry/i.test(navigator.userAgent);
    response = response || /iPhone|iPad|iPod/i.test(navigator.userAgent);
    return response
  };

  constructor(
    private generator: ProblemGeneratorService,
    private modalService: NgbModal,
    public result: ResultService,
    public likeManager: LikeManagerService,
    private levelManager: LevelManagerService
  ) { }

  ngOnInit(): void {
    this.problema = this.generator.generarNivel5();
    
    if (this.entrenamiento) {
      this.mostrarInformacion();
    }
    // this.step = 13;
  }

  capturar() {
    return new Promise((resolve, reject) => {
        let valores = Object.assign({}, this.valores);
        let resultado1 = Object.assign({}, this.resultado1);
        let resultado2 = Object.assign({}, this.resultado2);
        let multiplicador = Object.assign({}, this.multiplicador);
        let resultado = Object.assign({}, this.resultado);
        let reserva = Object.assign({}, this.reserva);
        let final = '' + this.final;
        let imagenes = ['', ''];

        this.valoresFinal = valores;
        this.resultado1Final = resultado1;
        this.resultado2Final = resultado2;
        this.multiplicadorFinal = multiplicador;
        this.resultadoFinal = resultado;
        this.reservaFinal = reserva;
        this.finalFinal = final;
        setTimeout(() => {
          html2canvas(<any>document.querySelector("#grid")).then(canvas => {
            const img = canvas.toDataURL('image/png')
            imagenes[0] = img;
            this.valoresFinal = this.problema.final.valores;
            this.resultado1Final = this.problema.final.valores[1];
            this.resultado2Final = this.problema.final.valores[2];
            this.multiplicadorFinal = this.problema.final.multiplicador;
            this.resultadoFinal = this.problema.final.resultado;
            this.reservaFinal = this.problema.final.reserva;
            this.finalFinal = this.problema.resultado;
            setTimeout(() => {
              html2canvas(<any>document.querySelector("#grid")).then(canvas => {
                const img = canvas.toDataURL('image/png')
                imagenes[1] = img;
                this.valoresFinal = valores;
                this.resultado1Final = resultado1;
                this.resultado2Final = resultado2;
                this.multiplicadorFinal = multiplicador;
                this.resultadoFinal = resultado;
                this.reservaFinal = reserva;
                this.finalFinal = final;
                resolve(imagenes);
              });
            }, 1);
          }).catch((err) => {
            resolve([])
          });
        }, 1);
    })
  }

  mostrarInformacion() {
    let x = this.valores[0][this.valores[0].length - (this.substep)];
    let y = this.multiplicador[1];
    if (this.step > 4) y = this.multiplicador[0];
    let z = this.valores[0].join('');
    const modalRef = this.modalService.open(InstruccionComponent, { size: 'lg', centered: !this.isMobile(), backdropClass: 'white', backdrop: (this.generator.instrucciones.nivel5[this.step].split('${split}').length > 1 ? 'static' : true) });
    modalRef.componentInstance.instruccion = this.generator.instrucciones.nivel5[this.step].replace('$Z', z).replace('$X', x).replace('$Y', y).replace('$X', x).replace('$Y', y);
    modalRef.result.then((result) => { }, (reason) => { });
  }

  mostrarAyuda() {
    let index1 = (this.problema.valor1.length - (this.step % (this.problema.valor1.length + 1))) +2;
    let index2 = this.step < 4 ? 1 : 0;
    const modalRef = this.modalService.open(TablaSelectorComponent, { size: 'xl', scrollable: true, backdrop: 'static', modalDialogClass: 'modal-ayuda' });
    modalRef.componentInstance.problema = this.generator.generarNivelBase(this.multiplicador[index2], this.valores[0][index1]);
    modalRef.componentInstance.entrenamiento = this.entrenamiento;
    modalRef.result.then((result) => {
      // let indexReserva = this.config2.reserva.findIndex((valor: any) => valor != 'disabled' && valor != 'readonly');
      // if (indexReserva != -1) {
      //   let indexResultado = this.config2.resultado.findIndex((valor: any) => valor != 'disabled' && valor != 'readonly');
      //   this.resultado2[indexResultado] = (result.resultado - 0) + (this.reserva2[indexReserva + 1] ? (this.reserva2[indexReserva + 1] - 0) : 0) - 0;
      //   this.reserva2[indexReserva] = result.reserva;
      //   if (this.resultado2[indexResultado] >= 10) {
      //     this.resultado2[indexResultado] %= 10;
      //     this.reserva2[indexReserva] -= -1;
      //   }
      // } else {
      //   let indexReserva = this.config1.reserva.findIndex((valor: any) => valor != 'disabled' && valor != 'readonly');
      //   let indexResultado = this.config1.resultado.findIndex((valor: any) => valor != 'disabled' && valor != 'readonly');
      //   this.resultado1[indexResultado] = (result.resultado - 0) + (this.reserva1[indexReserva + 1] ? (this.reserva1[indexReserva + 1] - 0) : 0) - 0;
      //   this.reserva1[indexReserva] = result.reserva;
      //   if (this.resultado1[indexResultado] >= 10) {
      //     this.resultado1[indexResultado] %= 10;
      //     this.reserva1[indexReserva] -= -1;
      //   }
      // }
    }, (reason) => { });
  }

  toggleEntrenamiento() {
    if (!this.result.haveUserId()) return;
    let msg = "¿Estás seguro de que quieres terminar el entrenamiento?";
    if (!this.entrenamiento) {
      msg = "<p>Cambiar a modo de entrenamiento hará que se pierda el progreso actual.</p><p>¿desea continuar?</p>"
    }
    const modalRef = this.modalService.open(QuestionComponent, { size: 'lg', scrollable: true, centered: !this.isMobile() });
    modalRef.componentInstance.mensaje = msg;
    modalRef.result.then((result) => {
      this.entrenamiento = !this.entrenamiento;
      this.result.cleanResults();
      this.restart();
    }, (reason) => { });
  }

  showArrow1(){
    return (this.step == 1 || this.step == 5 || this.step == 9) && this.entrenamiento;
  }

  showArrow2() {
    return (this.step == 2 || this.step == 6 || this.step == 10) && this.entrenamiento;
  }

  showArrow3() {
    return (this.step == 3 || this.step == 7 || this.step == 11) && this.entrenamiento;
  }

  isReady(): boolean {
    if (this.step == 0) {
      return !!this.valores[0][2] && !!this.valores[0][3] && !!this.valores[0][4] && !!this.multiplicador[0] && !!this.multiplicador[1];
    } else if (this.step < 5) {
      let step = this.substep;
      let result = true;
      if (step + 1 <= this.config1.reserva.length) result = result && (!!this.reserva1[this.reserva1.length - (step)] || this.reserva1[this.reserva1.length - (step)] == 0);
      if (!this.problema.steps[this.step].reserva) result = true;
      if (!this.entrenamiento) result = true;
      result = result && (!!this.resultado1[this.resultado1.length - (step)] || this.resultado1[this.resultado1.length - (step)] == 0);
      return result
    } else if (this.step < 9) {
      let step = this.substep;
      let result = true;
      if (step + 1 <= this.config2.reserva.length) result = result && (!!this.reserva2[this.reserva2.length - (step)] || this.reserva2[this.reserva2.length - (step)] == 0);
      if (!this.problema.steps[this.step].reserva) result = true;
      if (!this.entrenamiento) result = true;
      result = result && (!!this.resultado2[this.resultado2.length - (step)] || this.resultado2[this.resultado2.length - (step)] == 0);
      return result
    } else if (this.step < this.problema.steps.length) {
      let step = this.substep;
      let result = true;
      if (step + 1 <= this.config.reserva.length) result = result && (!!this.reserva[this.reserva.length - (step - 1)] || this.reserva[this.reserva.length - (step - 1)] == 0);
      if (!this.problema.steps[this.step].reserva) result = true;
      if (!this.entrenamiento) result = true;
      result = result && (!!this.resultado[this.resultado.length - (step)] || this.resultado[this.resultado.length - (step)] == 0);
      
      return result
    } else {
      return !!this.final;
    }
    return true
  }

  async checkFinalAnswer() {
    if (this.entrenamiento) {
      if (this.final == this.problema.resultado) {
        this.likeManager.showLike();
        this.restart();
      } else {
        if (this.entrenamiento) {
          this.stepsResults[this.step] = true;
          const modalRef = this.modalService.open(ErrorResultadoComponent, { size: 'lg', centered: !this.isMobile() });
          modalRef.componentInstance.problema = this.problema;
          modalRef.componentInstance.miResultado = this.final;
          modalRef.componentInstance.resultado = this.problema.resultado;
          modalRef.result.then((result) => { }, (reason) => { });
        }
      }
    } else {
      // Guardar resultado
      this.resultados.push({
        esperado: {
          resultado: '' + this.problema.resultado
        },
        registrado: {
          resultado: '' + this.final
        }
      })
      let imagenes = await this.capturar();
      this.result.addResult(this.resultados, imagenes);
      if (this.result.results.length == 7) {
        this.levelManager.showNextLevel();
      } else {
        this.restart();
      }
    }
  }

  checkFirstStep(){
    let result = true;
    this.problema.steps[this.step].multiplicador.forEach((value: number, index: number) => {
      result = result && value == this.multiplicador[index];
    });
    this.problema.steps[this.step].valores.forEach((row: number[], rowIndex: number) => {
      if (rowIndex > 0) return
      row.forEach((value: number, index: number) => {
        result = result && value == this.valores[rowIndex][index + 2];
      });
    });
    // Guardar resultado
    this.resultados.push({
      esperado: {
        factor1: this.problema.steps[0].valores[0].join(''),
        factor2: this.problema.steps[0].multiplicador.join('')
      },
      registrado: {
        factor1: this.valores[0].join(''),
        factor2: this.multiplicador.join('')
      },
    })

    this.step++;
    if (!result && this.entrenamiento) { // Falló la corrección
      const modalRef = this.modalService.open(ErrorComponent, { size: 'lg', centered: !this.isMobile(), backdrop: 'static' });
      modalRef.componentInstance.problema = this.problema;
      modalRef.componentInstance.valores = this.problema.steps[0].valores.map((e: any) => ['disabled', 'disabled', ...e]);
      modalRef.componentInstance.reserva = this.problema.steps.filter((e: any, index: number) => e.reserva && this.step > index).map((e: any) => e.reserva).concat(new Array(this.config.reserva.length - 1).fill(null)).slice(0, this.config.reserva.length - 1).reverse();
      modalRef.componentInstance.resultado = this.problema.steps.filter((e: any, index: number) => e.resultado && this.step > index).map((e: any) => e.resultado).concat(new Array(this.config.reserva.length).fill(null)).slice(0, this.config.reserva.length).reverse();
      modalRef.componentInstance.multiplicador = this.problema.steps[0].multiplicador;
      modalRef.componentInstance.config = this.config;
      modalRef.componentInstance.miReserva = this.reserva;
      modalRef.componentInstance.misValores = this.valores;
      modalRef.componentInstance.miResultado = this.resultado;
      modalRef.componentInstance.miConfig = this.config;
      modalRef.componentInstance.miMultiplicador = this.multiplicador;
      modalRef.result.then((result) => { }, (reason) => { });
      this.step--;
      this.stepsResults[this.step] = true;
    } else {
      let step = this.step - 1;
      // Deshabilitar valores formularios anteriores
      if (step < this.problema.steps.length) {
        if (step == 0) {
          this.config.valores = this.config.valores.map((row: any) => row.map((e: any) => (e == 'disabled') ? 'disabled' : 'readonly'));
          this.config.multiplicador = this.config.multiplicador.map((e: any) => (e == 'disabled') ? 'disabled' : 'readonly');
        } else {
          if (step <= this.config.reserva.length) this.config.reserva[this.config.reserva.length - (step + 1)] = 'readonly';
          this.config.resultado[this.config.resultado.length - (step)] = 'readonly';
        }
      }
      this.substep++;

      if (this.entrenamiento) {
        this.likeManager.showLike();
        this.mostrarInformacion()
      }

      // Establecer valores del siguiente formulario
      this.valores1 = [['', this.valores[0][2], this.valores[0][3], this.valores[0][4]]];
      this.multiplicador1 = [this.multiplicador[1]];

      let substep = this.substep - 1;
      // Habilitar siguientes formularios
      if (substep < this.problema.steps1.length) {
        if (substep <= this.config1.reserva.length) this.config1.reserva[this.config1.reserva.length - (substep + 2)] = '';
        this.config1.resultado[this.config1.resultado.length - (substep + 1)] = '';
      }
      // Deshabilitar valores formularios anteriores
      if (substep < this.problema.steps1.length) {
        if (substep == 0) {
          this.config1.valores1 = this.config1.valores.map((row: any) => row.map((e: any) => (e == 'disabled') ? 'disabled' : 'readonly'));
          this.config1.multiplicador1 = this.config1.multiplicador.map((e: any) => (e == 'disabled') ? 'disabled' : 'readonly');
        } else {
          if (substep <= this.config1.reserva.length) this.config1.reserva[this.config1.reserva.length - (substep + 1)] = 'readonly';
          this.config1.resultado[this.config1.resultado.length - (substep)] = 'readonly';
        }
      }
    }
  }

  checkStep(){
    let result = true;
    let config = this.config;
    let reserva = this.reserva;
    let resultado = this.resultado;
    let valores = this.valores;
    let steps = this.problema.steps1;
    let multiplicador = this.multiplicador;
    if (this.step >= 5) {
      config = this.config2;
      reserva = this.reserva2;
      resultado = this.resultado2;
      valores = this.valores2;
      steps = this.problema.steps2;
      multiplicador = this.multiplicador2;
    } else if (this.step >= 1) {
      config = this.config1;
      reserva = this.reserva1;
      resultado = this.resultado1;
      valores = this.valores1;
      steps = this.problema.steps1;
      multiplicador = this.multiplicador1;
    }
    if (this.substep < reserva.length) {
      result = result && ((!steps[this.substep].reserva && !reserva[reserva.length - this.substep]) || steps[this.substep].reserva == reserva[reserva.length - this.substep]);
      result = result && steps[this.substep].resultado == resultado[resultado.length - this.substep];
    } else {
      result = result && (!steps[this.substep].resultado && !resultado[resultado.length - this.substep]) || steps[this.substep].resultado == resultado[resultado.length - this.substep];
    }
    // Guardar resultado
    this.resultados.push({
      esperado: {
        producto: steps[this.substep].resultado ? '' + steps[this.substep].resultado : '',
        reserva: steps[this.substep].reserva ? '' + steps[this.substep].reserva : ''
      },
      registrado: {
        producto: resultado[resultado.length - this.substep] ? '' + resultado[resultado.length - this.substep] : '',
        reserva: reserva[reserva.length - this.substep] ? '' + reserva[reserva.length - this.substep] : ''
      }
    })

    this.step++;
    this.substep++;
    if (!result && this.entrenamiento) { // No fue correcto el resultado, se muestra el modal del error
      const modalRef = this.modalService.open(ErrorComponent, { size: 'lg', centered: !this.isMobile(), backdrop: 'static' });
      modalRef.componentInstance.problema = this.problema;
      modalRef.componentInstance.valores = steps[0].valores.map((e: any) => ['disabled', ...e]);
      modalRef.componentInstance.reserva = steps.filter((e: any, index: number) => (e.reserva || e.reserva == 0) && this.substep > index).map((e: any) => e.reserva).concat(new Array(config.reserva.length - 1).fill(null)).slice(0, config.reserva.length - 1).reverse();
      modalRef.componentInstance.resultado = steps.filter((e: any, index: number) => (e.resultado || e.resultado == 0) && this.substep > index).map((e: any) => e.resultado).concat(new Array(config.resultado.length).fill(null)).slice(0, config.resultado.length).reverse();
      modalRef.componentInstance.multiplicador = steps[0].multiplicador;
      modalRef.componentInstance.config = config;
      modalRef.componentInstance.miReserva = reserva;
      modalRef.componentInstance.misValores = valores;
      modalRef.componentInstance.miResultado = resultado;
      modalRef.componentInstance.miConfig = config;
      modalRef.componentInstance.miMultiplicador = multiplicador;
      modalRef.result.then((result) => { }, (reason) => { });
      this.step--;
      this.substep--;
      this.stepsResults[this.step] = true;
    } else { // Resultado correcto
      let substep = this.substep - 1;
      // Habilitar siguientes formularios
      if (substep < steps.length) {
        if (substep <= config.reserva.length) config.reserva[config.reserva.length - (substep + 2)] = '';
        config.resultado[config.resultado.length - (substep + 1)] = '';
      }
      // Deshabilitar valores formularios anteriores
      if (substep < steps.length) {
        if (substep == 0) {
          config.valores = config.valores.map((row: any) => row.map((e: any) => (e == 'disabled') ? 'disabled' : 'readonly'));
          config.multiplicador = config.multiplicador.map((e: any) => (e == 'disabled') ? 'disabled' : 'readonly');
        } else {
          if (substep <= config.reserva.length) config.reserva[config.reserva.length - (substep + 1)] = 'readonly';
          config.resultado[config.resultado.length - (substep)] = 'readonly';
        }
      }

      // Preparar siguiente subflujo si llega al paso 4
      if (this.step == 5) {
        this.substep = 1;
        this.valores[1] = ['', ...this.resultado1];
        this.valores2 = [['', this.valores[0][2], this.valores[0][3], this.valores[0][4]]];
        this.multiplicador2 = [this.multiplicador[0]];

        let substep = this.substep - 1;
        // Habilitar siguientes formularios
        if (substep < this.problema.steps2.length) {
          if (substep <= this.config2.reserva.length) this.config2.reserva[this.config2.reserva.length - (substep + 2)] = '';
          this.config2.resultado[this.config2.resultado.length - (substep + 1)] = '';
        }
        // Deshabilitar valores formularios anteriores
        if (substep < this.problema.steps1.length) {
          if (substep == 0) {
            this.config2.valores1 = this.config2.valores.map((row: any) => row.map((e: any) => (e == 'disabled') ? 'disabled' : 'readonly'));
            this.config2.multiplicador1 = this.config2.multiplicador.map((e: any) => (e == 'disabled') ? 'disabled' : 'readonly');
          } else {
            if (substep <= this.config2.reserva.length) this.config2.reserva[this.config2.reserva.length - (substep + 1)] = 'readonly';
            this.config2.resultado[this.config2.resultado.length - (substep)] = 'readonly';
          }
        }
      }

      // Preparar siguiente subflujo si llega al paso 7
      if (this.step == 9) {
        this.substep = 1;
        this.valores[2] = [...this.resultado2, ''];
        // this.config.resultado = this.config.resultado.map((e: any) => '');
        // this.config.reserva = this.config.reserva.map((e: any) => e == 'disabled' ? 'disabled' : '');
        let step = this.step - 1;
        // Habilitar siguientes formularios
        if (step < this.problema.steps.length) {
          this.config.resultado[this.config.resultado.length - (this.substep)] = '';
        }
      }
      // Saltar siguiente paso
      if (((this.step == 4 && !this.reserva1[0]) || (this.step == 8 && !this.reserva2[0]))) {
        this.listo();
        return;
      }
      // Mostrar instrucción
      if (this.entrenamiento) {
        this.likeManager.showLike();
        this.mostrarInformacion()
      }
    }
  }

  checkLastSteps(){
    let result = true;
    if (this.substep < this.config.reserva.length && this.substep > 1) {
      result = result && ((!this.problema.steps[this.step].reserva && !this.reserva[this.reserva.length - this.substep]) || this.problema.steps[this.step].reserva == this.reserva[this.reserva.length - (this.substep - 1)]);
      result = result && this.problema.steps[this.step].resultado == this.resultado[this.resultado.length - this.substep];
    } else {
      result = result && (!this.problema.steps[this.step].resultado && !this.resultado[this.resultado.length - this.substep]) || this.problema.steps[this.step].resultado == this.resultado[this.resultado.length - this.substep];
    }
    // Guardar resultado
    this.resultados.push({
      esperado: {
        producto: this.problema.steps[this.step].resultado ? '' + this.problema.steps[this.step].resultado : '',
        reserva: this.problema.steps[this.step].reserva ? '' + this.problema.steps[this.step].reserva : ''
      },
      registrado: {
        producto: this.resultado[this.resultado.length - this.substep] ? '' + this.resultado[this.resultado.length - this.substep] : '',
        reserva: this.reserva[this.reserva.length + 1 - this.substep] ? '' + this.reserva[this.reserva.length + 1 - this.substep] : ''
      }
    })

    this.step++;
    this.substep++;
    if (!result && this.entrenamiento) {
      const modalRef = this.modalService.open(ErrorComponent, { size: 'lg', centered: !this.isMobile(), backdrop: 'static' });
      modalRef.componentInstance.problema = this.problema;
      modalRef.componentInstance.valores = this.valores;
      modalRef.componentInstance.reserva = this.problema.steps.filter((e: any, index: number) => (e.reserva || e.reserva == 0) && this.step > index && index > 8).map((e: any) => e.reserva).concat(new Array(this.config.reserva.length - 1).fill(null)).slice(0, this.config.reserva.length - 2).reverse();
      modalRef.componentInstance.resultado = this.problema.steps.filter((e: any, index: number) => (e.resultado || e.resultado == 0) && this.step > index && index > 8).map((e: any) => e.resultado).concat(new Array(this.config.resultado.length).fill(null)).slice(0, this.config.resultado.length).reverse();

      modalRef.componentInstance.multiplicador = [];
      modalRef.componentInstance.config = this.config;
      modalRef.componentInstance.miReserva = this.reserva;
      modalRef.componentInstance.misValores = this.valores;
      modalRef.componentInstance.miResultado = this.resultado;
      modalRef.componentInstance.miConfig = this.config;
      modalRef.componentInstance.miMultiplicador = [];
      modalRef.result.then((result) => { }, (reason) => { });
      this.step--;
      this.substep--;
      this.stepsResults[this.step] = true;
    } else {
      let step = this.step - 1;
      let substep = this.substep - 1;
      // Habilitar siguientes formularios
      if (step < this.problema.steps.length) {
        if (substep <= this.config.reserva.length) this.config.reserva[this.config.reserva.length - (substep + 2)] = '';
        this.config.resultado[this.config.resultado.length - (substep + 1)] = '';
      }
      // Deshabilitar valores formularios anteriores
      if (step < this.problema.steps.length) {
        if (substep <= this.config.reserva.length && substep > 1) this.config.reserva[this.config.reserva.length - (substep + 1)] = 'readonly';
        this.config.resultado[this.config.resultado.length - (substep)] = 'readonly';
      }
      // Comprobar si es el último campo para saltar paso
      if (this.step == this.problema.steps.length - 1 && !this.reserva[0] && !this.resultado2[0]) {
        this.listo();
        return;
      }
      // Mostrar información
      if (this.entrenamiento) {
        this.likeManager.showLike();
        this.mostrarInformacion()
      }
    }
  }

  listo() {
    this.working = true;
    if (!this.stepsResults[this.step]) return this.working = false;
    this.stepsResults[this.step] = false;
    if (this.step >= this.problema.steps.length) {
      this.checkFinalAnswer();
      return this.working = false;
    };
    // Comprobar corrección en cada paso
    if (this.step == 0) { // Primer paso es diferente
      this.checkFirstStep();
    } else if (this.step >= 1 && this.step < 9) { // Del paso 2 al paso 7 se utilizan las configuraciones base
      this.checkStep();
    } else { // De ahora en adelante se utiliza el método básico
      this.checkLastSteps();
    }
    return this.working = false;
  }

  restart() {
    this.step = 0;
    this.substep = 0;
    this.secuencia = 1;
    this.problema = this.generator.generarNivel5();
    this.reserva = ['','',''];
    this.valores = [[], [], []];
    this.resultado = [];
    this.multiplicador = ['', ''];
    this.final = '';
    this.stepsResults = new Array(30).fill(true);

    this.reserva1 = ['','',''];
    this.valores1 = [[]];
    this.resultado1 = [];
    this.multiplicador1 = [''];

    this.reserva2 = ['','',''];
    this.valores2 = [[]];
    this.resultado2 = [];
    this.multiplicador2 = [''];
    this.resultados = [];

    this.config = {
      valores: [['disabled', 'disabled', null, null, null], ['disabled', 'readonly', 'readonly', 'readonly', 'readonly'], ['readonly', 'readonly', 'readonly', 'readonly', 'disabled']], multiplicador: [null, null], reserva: ['readonly', 'readonly', 'readonly', 'disabled', 'disabled'], resultado: ['readonly', 'readonly', 'readonly', 'readonly', 'readonly']
    };

    this.config1 = {
      valores: [['disabled', null, null, null]], multiplicador: [null], reserva: ['readonly', 'readonly', 'readonly', 'disabled'], resultado: ['readonly', 'readonly', 'readonly', 'readonly']
    };

    this.config2 = {
      valores: [['disabled', null, null, null]], multiplicador: [null], reserva: ['readonly', 'readonly', 'readonly', 'disabled'], resultado: ['readonly', 'readonly', 'readonly', 'readonly']
    };
    if (this.entrenamiento) {
      this.mostrarInformacion();
    }
  }

}
