<div class="contenedor-principal h-100 w-100 d-flex flex-column justify-content-center p-5">
    <div class="options w-100 d-flex w-100 gap-3 justify-content-between">
        <div id="tools" class="d-flex flex-column gap-3">
            <button class="btn" (click)="mostrarInformacion()"><img id="btn-info" src="./assets/templates/info.svg"
                    alt="Información"></button>
            <button *ngIf="step > 0 && step < 4" class="btn btn-ayuda" (click)="mostrarAyuda()"><img id="btn-ayuda"
                    src="./assets/templates/tool.svg" alt="Información"></button>
        </div>

        <div class="options2 w-100 d-flex justify-content-end">
            <app-ring [step]="result.results.length" *ngIf="!entrenamiento"></app-ring>
        </div>
    </div>
    <div class="d-flex flex-column w-100 h-100">

        <div class="work-container w-100">
            <div class="work-area w-100 d-flex justify-content-evenly p-3 flex-column flex-lg-row gap-5 gap-lg-3">
                <div class="problema d-flex justify-content-center align-items-center">
                    <div class="num1">{{problema.valor1.join('')}}</div>
                    <i><img class="times-dot-sm" src="./assets/templates/dot.svg" alt=""></i>
                    <div class="num2">{{problema.valor2.join('')}}</div>
                    <div class="mx-2"> = </div>
                    <input type="text" autocomplete="off" mask="separator.0" thousandSeparator="." [(ngModel)]="final"
                        *ngIf="step == problema.steps.length">
                    <input type="text" autocomplete="off" mask="separator.0" thousandSeparator="." readonly
                        [(ngModel)]="final" *ngIf="step != problema.steps.length">
                </div>
                <div>
                    <div class="grid">
                        <div class="encabezados-mil">
                            <div class="cell only-child">MIL<div class="bubble"></div>
                            </div>
                        </div>
                        <div class="encabezados">
                            <div class="cell centena">U</div>
                            <div class="cell centena">C</div>
                            <div class="cell decena">D</div>
                            <div class="cell unidad">U</div>
                        </div>
                        <div class="reserva">
                            <div class="cell valor" *ngFor="let valor of config.reserva; let index = index;">
                                <div class="area">
                                    <div class="white-cell">
                                        <input type="text" mask="0" [id]="'reserva' + index"
                                            [(ngModel)]="reserva[index]"
                                            *ngIf="valor != 'disabled' && valor != 'readonly'" autocomplete="off">
                                        <input type="text" mask="0" disabled *ngIf="valor == 'disabled'"
                                            autocomplete="off" tabindex="-1">
                                        <input type="text" mask="0" readonly *ngIf="valor == 'readonly'"
                                            [(ngModel)]="reserva[index]" autocomplete="off" tabindex="-1">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="valores" *ngFor="let row of config.valores; let rowIndex = index">
                            <div class="cell valor" *ngFor="let valor of row; let index = index">
                                <div class="area">
                                    <input type="text" mask="0" [(ngModel)]="valores[rowIndex][index]"
                                        *ngIf="valor != 'disabled' && valor != 'readonly'" autocomplete="off">
                                    <input type="text" mask="0" disabled *ngIf="valor == 'disabled'" autocomplete="off"
                                        tabindex="-1">
                                    <input type="text" mask="0" readonly *ngIf="valor == 'readonly'"
                                        [(ngModel)]="valores[rowIndex][index]" autocomplete="off" tabindex="-1">
                                </div>
                            </div>
                            <div class="cell multiplicacion" *ngIf="rowIndex == config.valores.length -1">
                                <div class="area">
                                    <div><i><img class="times-dot" src="./assets/templates/dot.svg"
                                                alt=""></i></div>
                                </div>
                            </div>
                            <ng-container *ngIf="rowIndex == config.valores.length -1">
                                <div class="cell multiplicando"
                                    *ngFor="let valor of config.multiplicador; let index = index">
                                    <div class="area">
                                        <input type="text" mask="0" [(ngModel)]="multiplicador[index]"
                                            *ngIf="valor != 'disabled' && valor != 'readonly'" autocomplete="off">
                                        <input type="text" mask="0" disabled *ngIf="valor == 'disabled'"
                                            autocomplete="off" tabindex="-1">
                                        <input type="text" mask="0" readonly *ngIf="valor == 'readonly'"
                                            [(ngModel)]="multiplicador[index]" autocomplete="off" tabindex="-1">
                                    </div>
                                </div>
                            </ng-container>
                            <div class="arrow arrow-1" *ngIf="showArrow1()">
                                <img src="./assets/templates/arrow1.svg" alt="">
                            </div>
                            <div class="arrow arrow-2" *ngIf="showArrow2()">
                                <img src="./assets/templates/arrow2.svg" alt="">
                            </div>
                            <div class="arrow arrow-3" *ngIf="showArrow3()">
                                <img src="./assets/templates/arrow3.svg" alt="">
                            </div>
                        </div>


                        <div class="resultado">
                            <div class="cell" *ngFor="let valor of config.resultado; let index = index">
                                <div class="area">
                                    <input type="text" mask="0" [(ngModel)]="resultado[index]"
                                        *ngIf="valor != 'disabled' && valor != 'readonly'" autocomplete="off">
                                    <input type="text" mask="0" disabled *ngIf="valor == 'disabled'" autocomplete="off"
                                        tabindex="-1">
                                    <input type="text" mask="0" readonly *ngIf="valor == 'readonly'"
                                        [(ngModel)]="resultado[index]" autocomplete="off" tabindex="-1">
                                </div>
                            </div>
                        </div>
                        <div class="separador">
                            <div class="cell extra-i"></div>
                            <div class="cell centena" *ngFor="let valor of config.resultado; let index = index">
                            </div>
                            <div class="cell extra-d"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="work-area w-100 d-flex justify-content-evenly p-4 pb-5 flex-column flex-lg-row gap-5 gap-lg-3"
                id="grid">
                <div class="problema d-flex justify-content-center align-items-center">
                    <div class="num1">{{problema.valor1.join('')}}</div>
                    <i><img class="times-dot-sm" src="./assets/templates/dot.svg" alt=""></i>
                    <div class="num2">{{problema.valor2.join('')}}</div>
                    <div class="mx-2"> = </div>
                    <input type="text" autocomplete="off" mask="separator.0" thousandSeparator="." readonly
                        [(ngModel)]="finalFinal" tabindex="-1">
                </div>
                <div>
                    <div class="grid">
                        <div class="encabezados-mil">
                            <div class="cell only-child">MIL<div class="bubble"></div>
                            </div>
                        </div>
                        <div class="encabezados">
                            <div class="cell centena">U</div>
                            <div class="cell centena">C</div>
                            <div class="cell decena">D</div>
                            <div class="cell unidad">U</div>
                        </div>
                        <div class="reserva">
                            <div class="cell valor" *ngFor="let valor of config.reserva; let index = index;">
                                <div class="area">
                                    <div class="white-cell">
                                        <input type="text" mask="0" readonly [(ngModel)]="reservaFinal[index]"
                                            autocomplete="off" tabindex="-1">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="valores" *ngFor="let row of config.valores; let rowIndex = index">
                            <div class="cell valor" *ngFor="let valor of row; let index = index">
                                <div class="area">
                                    <input type="text" mask="0" readonly [(ngModel)]="valoresFinal[rowIndex][index]"
                                        autocomplete="off" tabindex="-1">
                                </div>
                            </div>
                            <div class="cell multiplicacion" *ngIf="rowIndex == config.valores.length -1">
                                <div class="area">
                                    <div><i><img class="times-dot" src="./assets/templates/dot.svg"
                                                alt=""></i></div>
                                </div>
                            </div>
                            <ng-container *ngIf="rowIndex == config.valores.length -1">
                                <div class="cell multiplicando"
                                    *ngFor="let valor of config.multiplicador; let index = index">
                                    <div class="area">
                                        <input type="text" mask="0" readonly [(ngModel)]="multiplicadorFinal[index]"
                                            autocomplete="off" tabindex="-1">
                                    </div>
                                </div>
                            </ng-container>
                        </div>


                        <div class="resultado">
                            <div class="cell" *ngFor="let valor of config.resultado; let index = index">
                                <div class="area">
                                    <input type="text" mask="0" readonly [(ngModel)]="resultadoFinal[index]"
                                        autocomplete="off" tabindex="-1">
                                </div>
                            </div>
                        </div>
                        <div class="separador">
                            <div class="cell extra-i"></div>
                            <div class="cell centena" *ngFor="let valor of config.resultado; let index = index">
                            </div>
                            <div class="cell extra-d"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer btns w-100 d-flex justify-content-between">
        <div class="like slide-up" *ngIf="likeManager.like"><img src="./assets/templates/smile.svg" alt=""></div>
        <button class="btn btn-reiniciar mt-auto" tabindex="-1" (click)="restart()">Reiniciar</button>
        <div class="d-flex flex-column justify-content-center align-items-center">
            <div class="title">Entrenamiento</div>
            <button class="btn btn-entrenamiento mt-auto" *ngIf="entrenamiento" (click)="toggleEntrenamiento()"
                tabindex="-1">Activado</button>
            <button class="btn btn-desentrenamiento mt-auto" *ngIf="!entrenamiento" (click)="toggleEntrenamiento()"
                tabindex="-1">Desactivado</button>
        </div>
        <button class="btn btn-listo mt-auto" (click)="listo()" *ngIf="isReady()">Listo</button>
        <button class="btn btn-listo disabled mt-auto" *ngIf="!isReady()">Listo</button>
    </div>
</div>