<div class="contenedor-principal h-100 w-100 d-flex flex-column justify-content-center p-5">
    <div class="options w-100 d-flex w-100 gap-3 justify-content-between">
        <div id="tools" class="d-flex flex-column gap-3">
            <button class="btn" (click)="mostrarInformacion()"><img id="btn-info" src="./assets/templates/info.svg"
                    alt="Información"></button>
            <button *ngIf="(step > 0 && step < 4) || (step > 4 && step < 8)" class="btn btn-ayuda" (click)="mostrarAyuda()"><img
                    id="btn-ayuda" src="./assets/templates/tool.svg" alt="Información"></button>
        </div>

        <div class="options2 w-100 d-flex justify-content-end">
            <app-ring [step]="result.results.length" *ngIf="!entrenamiento"></app-ring>
        </div>
    </div>
    <div class="d-flex flex-column w-100 h-100">
        <div class="work-container w-100 smt-5" [ngClass]="{'n5': step > 0 && step < 5}">
            <div class="p-3 gap-3">
                <div class="work-area mb-5 w-100 d-flex justify-content-evenly">
                    <div class="problema d-flex justify-content-center align-items-center">
                        <div class="num1">{{problema.valor1.join('')}}</div>
                        <i><img class="times-dot-sm" src="./assets/templates/dot.svg" alt=""></i>
                        <div class="num2">{{problema.valor2.join('')}}</div>
                        <div class="mx-2"> = </div>
                        <input type="text" autocomplete="off" mask="separator.0" thousandSeparator="."
                            [(ngModel)]="final" *ngIf="step == problema.steps.length">
                        <input type="text" autocomplete="off" mask="separator.0" thousandSeparator="." readonly
                            [(ngModel)]="final" *ngIf="step != problema.steps.length">
                    </div>
                </div>
                <div class="work-area h-100 w-100 gap-5 d-flex justify-content-evenly">
                    <div class="grid">
                        <div class="encabezados-mil">
                            <div class="cell">
                                <div class="bubble"></div>
                            </div>
                            <div class="cell"></div>
                            <p class="overlay">MIL</p>
                        </div>
                        <div class="encabezados">
                            <div class="cell centena">D</div>
                            <div class="cell centena">U</div>
                            <div class="cell centena">C</div>
                            <div class="cell decena">D</div>
                            <div class="cell unidad">U</div>
                        </div>
                        <div class="valores">
                            <div class="cell valor" *ngFor="let valor of config.valores[0]; let index = index">
                                <div class="area">
                                    <input type="text" mask="0" [(ngModel)]="valores[0][index]"
                                        *ngIf="valor != 'disabled' && valor != 'readonly'" autocomplete="off">
                                    <input type="text" mask="0" disabled *ngIf="valor == 'disabled'" autocomplete="off"
                                        tabindex="-1">
                                    <input type="text" mask="0" readonly *ngIf="valor == 'readonly'"
                                        [(ngModel)]="valores[0][index]" autocomplete="off" tabindex="-1">
                                </div>
                            </div>
                            <div class="cell multiplicacion">
                                <div class="area">
                                    <div><i><img class="times-dot" src="./assets/templates/dot.svg"
                                                alt=""></i></div>
                                </div>
                            </div>
                            <ng-container>
                                <div class="cell multiplicando"
                                    *ngFor="let valor of config.multiplicador; let index = index">
                                    <div class="area">
                                        <input type="text" mask="0" [(ngModel)]="multiplicador[index]"
                                            *ngIf="valor != 'disabled' && valor != 'readonly'" autocomplete="off">
                                        <input type="text" mask="0" disabled *ngIf="valor == 'disabled'"
                                            autocomplete="off" tabindex="-1">
                                        <input type="text" mask="0" readonly *ngIf="valor == 'readonly'"
                                            [(ngModel)]="multiplicador[index]" autocomplete="off" tabindex="-1">
                                    </div>
                                </div>
                            </ng-container>
                        </div>

                        <div class="valores">
                            <div class="cell valor" *ngFor="let valor of [1]">
                                <div class="area">
                                    <input type="text" mask="0" disabled autocomplete="off" tabindex="-1">
                                </div>
                            </div>
                            <div class="cell valor" *ngFor="let valor of config1.resultado; let index = index">
                                <div class="area">
                                    <input type="text" mask="0" readonly [(ngModel)]="resultado1[index]"
                                        [ngClass]="{'enfasis': resultado1[index] && step < 5}" autocomplete="off"
                                        tabindex="-1">
                                </div>
                            </div>
                        </div>

                        <div class="valores">
                            <div class="cell valor" *ngFor="let valor of config.valores[2]; let index = index">
                                <div class="area">
                                    <input type="text" mask="0" readonly [(ngModel)]="resultado2[index]"
                                        [ngClass]="{'enfasis': resultado2[index] && step < 9}" autocomplete="off"
                                        tabindex="-1">
                                    <div class="face" *ngIf="index == 4">
                                        <img src="./assets/templates/face.svg" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="resultado">
                            <div class="cell" *ngFor="let valor of config.resultado; let index = index">
                                <div class="area">
                                    <input type="text" mask="0" disabled *ngIf="valor == 'disabled'" autocomplete="off"
                                        tabindex="-1">
                                    <input type="text" mask="0" readonly *ngIf="valor != 'disabled'"
                                        [(ngModel)]="resultado[index]" autocomplete="off" tabindex="-1">
                                </div>
                            </div>
                        </div>
                        <div class="separador separador-0">
                            <div class="cell extra-i"></div>
                            <div class="cell centena" *ngFor="let valor of config.resultado; let index = index"></div>
                            <div class="cell extra-d"></div>
                        </div>

                        <div class="separador separador-2">
                            <div class="cell extra-i"></div>
                            <div class="cell centena" *ngFor="let valor of config.resultado; let index = index"></div>
                            <div class="cell extra-d"></div>
                        </div>
                    </div>

                    <div class="grid grid-1 grid-sm" *ngIf="(step > 0 && step < 9)">
                        <div class="encabezados-mil">
                            <div class="cell only-child">MIL<div class="bubble"></div>
                            </div>
                        </div>
                        <div class="encabezados">
                            <div class="cell centena">U</div>
                            <div class="cell centena">C</div>
                            <div class="cell decena">D</div>
                            <div class="cell unidad">U</div>
                        </div>
                        <div class="reserva">
                            <div class="cell valor" *ngFor="let valor of config1.reserva; let index = index;">
                                <div class="area">
                                    <div class="white-cell">
                                        <input type="text" mask="0" [id]="'reserva' + index"
                                            [(ngModel)]="reserva1[index]"
                                            *ngIf="valor != 'disabled' && valor != 'readonly'" autocomplete="off">
                                        <input type="text" mask="0" disabled *ngIf="valor == 'disabled'"
                                            autocomplete="off" tabindex="-1">
                                        <input type="text" mask="0" readonly *ngIf="valor == 'readonly'"
                                            [(ngModel)]="reserva1[index]" autocomplete="off" tabindex="-1">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="valores" *ngFor="let row of config1.valores; let rowIndex = index">
                            <div class="cell valor" *ngFor="let valor of row; let index = index">
                                <div class="area">
                                    <input type="text" mask="0" disabled *ngIf="valor == 'disabled'" autocomplete="off"
                                        tabindex="-1">
                                    <input type="text" mask="0" readonly *ngIf="valor != 'disabled'"
                                        [(ngModel)]="valores1[rowIndex][index]" autocomplete="off" tabindex="-1">
                                </div>
                            </div>
                            <div class="cell multiplicacion" *ngIf="rowIndex == 0">
                                <div class="area">
                                    <div><i><img class="times-dot" src="./assets/templates/dot.svg"
                                                alt=""></i></div>
                                </div>
                            </div>
                            <ng-container *ngIf="rowIndex == 0">
                                <div class="cell multiplicando">
                                    <div class="area">
                                        <input type="text" mask="0" disabled
                                            *ngIf="config1.multiplicador[0] == 'disabled'" autocomplete="off"
                                            tabindex="-1">
                                        <input type="text" mask="0" readonly
                                            *ngIf="config1.multiplicador[0] != 'disabled'"
                                            [(ngModel)]="multiplicador1[0]" autocomplete="off" tabindex="-1">
                                    </div>
                                </div>
                            </ng-container>
                            <div class="arrow arrow-1" *ngIf="showArrow1()">
                                <img src="./assets/templates/arrow1.svg" alt="">
                            </div>
                            <div class="arrow arrow-2" *ngIf="showArrow2()">
                                <img src="./assets/templates/arrow2.svg" alt="">
                            </div>
                            <div class="arrow arrow-3" *ngIf="showArrow3()">
                                <img src="./assets/templates/arrow3.svg" alt="">
                            </div>
                        </div>


                        <div class="resultado">
                            <div class="cell" *ngFor="let valor of config1.resultado; let index = index">
                                <div class="area">
                                    <input type="text" mask="0" [(ngModel)]="resultado1[index]"
                                        *ngIf="valor != 'disabled' && valor != 'readonly'" autocomplete="off">
                                    <input type="text" mask="0" disabled *ngIf="valor == 'disabled'" autocomplete="off"
                                        tabindex="-1">
                                    <input type="text" mask="0" readonly *ngIf="valor == 'readonly'"
                                        [(ngModel)]="resultado1[index]" autocomplete="off" tabindex="-1">
                                </div>
                            </div>
                        </div>
                        <div class="separador">
                            <div class="cell extra-i"></div>
                            <div class="cell centena" *ngFor="let valor of config1.resultado; let index = index"></div>
                            <div class="cell extra-d"></div>
                        </div>
                    </div>

                    <div class="grid grid-2 grid-sm" *ngIf="step > 4 && step < 9">
                        <div class="encabezados-mil">
                            <div class="cell only-child">MIL<div class="bubble"></div>
                            </div>
                        </div>
                        <div class="encabezados">
                            <div class="cell centena">U</div>
                            <div class="cell centena">C</div>
                            <div class="cell decena">D</div>
                            <div class="cell unidad">U</div>
                        </div>
                        <div class="reserva">
                            <div class="cell valor" *ngFor="let valor of config2.reserva; let index = index;">
                                <div class="area">
                                    <div class="white-cell">
                                        <input type="text" mask="0" [id]="'reserva' + index"
                                            [(ngModel)]="reserva2[index]"
                                            *ngIf="valor != 'disabled' && valor != 'readonly'" autocomplete="off">
                                        <input type="text" mask="0" disabled *ngIf="valor == 'disabled'"
                                            autocomplete="off" tabindex="-1">
                                        <input type="text" mask="0" readonly *ngIf="valor == 'readonly'"
                                            [(ngModel)]="reserva2[index]" autocomplete="off" tabindex="-1">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="valores" *ngFor="let row of config2.valores; let rowIndex = index">
                            <div class="cell valor" *ngFor="let valor of row; let index = index">
                                <div class="area">
                                    <input type="text" mask="0" disabled *ngIf="valor == 'disabled'" autocomplete="off"
                                        tabindex="-1">
                                    <input type="text" mask="0" readonly *ngIf="valor != 'disabled'"
                                        [(ngModel)]="valores2[rowIndex][index]" autocomplete="off" tabindex="-1">
                                </div>
                            </div>
                            <div class="cell multiplicacion" *ngIf="rowIndex == 0">
                                <div class="area">
                                    <div><i><img class="times-dot" src="./assets/templates/dot.svg"
                                                alt=""></i></div>
                                </div>
                            </div>
                            <ng-container *ngIf="rowIndex == 0">
                                <div class="cell multiplicando">
                                    <div class="area">
                                        <input type="text" mask="0" disabled
                                            *ngIf="config2.multiplicador[0] == 'disabled'" autocomplete="off"
                                            tabindex="-1">
                                        <input type="text" mask="0" readonly
                                            *ngIf="config2.multiplicador[0] != 'disabled'"
                                            [(ngModel)]="multiplicador2[0]" autocomplete="off" tabindex="-1">
                                    </div>
                                </div>
                            </ng-container>
                            <div class="arrow arrow-1" *ngIf="showArrow1()">
                                <img src="./assets/templates/arrow1.svg" alt="">
                            </div>
                            <div class="arrow arrow-2" *ngIf="showArrow2()">
                                <img src="./assets/templates/arrow2.svg" alt="">
                            </div>
                            <div class="arrow arrow-3" *ngIf="showArrow3()">
                                <img src="./assets/templates/arrow3.svg" alt="">
                            </div>
                        </div>


                        <div class="resultado">
                            <div class="cell" *ngFor="let valor of config2.resultado; let index = index">
                                <div class="area">
                                    <input type="text" mask="0" [(ngModel)]="resultado2[index]"
                                        *ngIf="valor != 'disabled' && valor != 'readonly'" autocomplete="off">
                                    <input type="text" mask="0" disabled *ngIf="valor == 'disabled'" autocomplete="off"
                                        tabindex="-1">
                                    <input type="text" mask="0" readonly *ngIf="valor == 'readonly'"
                                        [(ngModel)]="resultado2[index]" autocomplete="off" tabindex="-1">
                                </div>
                            </div>
                        </div>
                        <div class="separador">
                            <div class="cell extra-i"></div>
                            <div class="cell centena" *ngFor="let valor of config2.resultado; let index = index"></div>
                            <div class="cell extra-d"></div>
                        </div>
                    </div>

                    <div class="grid grid-3 grid-sm" *ngIf="step >= 9 && step < 14">
                        <div class="encabezados-mil">
                            <div class="cell">
                                <div class="bubble"></div>
                            </div>
                            <div class="cell"></div>
                            <p class="overlay">MIL</p>
                        </div>
                        <div class="encabezados">
                            <div class="cell centena">D</div>
                            <div class="cell centena">U</div>
                            <div class="cell decena">C</div>
                            <div class="cell decena">D</div>
                            <div class="cell unidad">U</div>
                        </div>
                        <div class="reserva">
                            <div class="cell valor" *ngFor="let valor of config.reserva; let index = index;">
                                <div class="area">
                                    <div class="white-cell">
                                        <input type="text" mask="0" [id]="'reserva' + index"
                                            [(ngModel)]="reserva[index]"
                                            *ngIf="valor != 'disabled' && valor != 'readonly'" autocomplete="off">
                                        <input type="text" mask="0" disabled *ngIf="valor == 'disabled'"
                                            autocomplete="off" tabindex="-1">
                                        <input type="text" mask="0" readonly *ngIf="valor == 'readonly'"
                                            [(ngModel)]="reserva[index]" autocomplete="off" tabindex="-1">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="valores" *ngFor="let row of config.valores; let rowIndex = index">
                            <ng-container *ngIf="rowIndex > 0">
                                <div class="cell valor" *ngFor="let valor of row; let index = index">
                                    <div class="area">
                                        <input type="text" mask="0" disabled *ngIf="valor == 'disabled'"
                                            autocomplete="off" tabindex="-1">
                                        <input type="text" mask="0" readonly *ngIf="valor != 'disabled'"
                                            [(ngModel)]="valores[rowIndex][index]" autocomplete="off" tabindex="-1">
                                        <div class="face" *ngIf="index == 4 && rowIndex == 2">
                                            <img src="./assets/templates/face.svg" alt="">
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>

                        <div class="resultado">
                            <div class="cell" *ngFor="let valor of config.resultado; let index = index">
                                <div class="area">
                                    <input type="text" mask="0" [(ngModel)]="resultado[index]"
                                        *ngIf="valor != 'disabled' && valor != 'readonly'" autocomplete="off">
                                    <input type="text" mask="0" disabled *ngIf="valor == 'disabled'" autocomplete="off"
                                        tabindex="-1">
                                    <input type="text" mask="0" readonly *ngIf="valor == 'readonly'"
                                        [(ngModel)]="resultado[index]" autocomplete="off" tabindex="-1">
                                </div>
                            </div>
                        </div>
                        <div class="separador separador-2">
                            <div class="cell extra-i"></div>
                            <div class="cell centena" *ngFor="let valor of config.resultado; let index = index"></div>
                            <div class="cell extra-d"></div>
                        </div>
                        <div class="plus separador-2">
                            <i><img style="height: 30px; width: 30px;" src="./assets/templates/plus.svg" alt=""></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-4 pb-5 gap-3" id="grid">
                <div class="work-area mb-5 w-100 d-flex justify-content-evenly">
                    <div class="problema d-flex justify-content-center align-items-center">
                        <div class="num1">{{problema.valor1.join('')}}</div>
                        <i><img class="times-dot-sm" src="./assets/templates/dot.svg" alt=""></i>
                        <div class="num2">{{problema.valor2.join('')}}</div>
                        <div class="mx-2"> = </div>
                        <input type="text" autocomplete="off" mask="separator.0" thousandSeparator="." readonly
                            [(ngModel)]="finalFinal" tabindex="-1">
                    </div>
                </div>
                <div class="work-area h-100 w-100 gap-5 d-flex justify-content-evenly">
                    <div class="grid">
                        <div class="encabezados-mil">
                            <div class="cell">
                                <div class="bubble"></div>
                            </div>
                            <div class="cell"></div>
                            <p class="overlay">MIL</p>
                        </div>
                        <div class="encabezados">
                            <div class="cell centena">D</div>
                            <div class="cell centena">U</div>
                            <div class="cell centena">C</div>
                            <div class="cell decena">D</div>
                            <div class="cell unidad">U</div>
                        </div>
                        <div class="valores">
                            <div class="cell valor" *ngFor="let valor of config.valores[0]; let index = index">
                                <div class="area">
                                    <input type="text" mask="0" readonly [(ngModel)]="valoresFinal[0][index]"
                                        autocomplete="off" tabindex="-1">
                                </div>
                            </div>
                            <div class="cell multiplicacion">
                                <div class="area">
                                    <div><i><img class="times-dot" src="./assets/templates/dot.svg"
                                                alt=""></i></div>
                                </div>
                            </div>
                            <ng-container>
                                <div class="cell multiplicando"
                                    *ngFor="let valor of config.multiplicador; let index = index">
                                    <div class="area">
                                        <input type="text" mask="0" readonly [(ngModel)]="multiplicadorFinal[index]"
                                            autocomplete="off" tabindex="-1">
                                    </div>
                                </div>
                            </ng-container>
                        </div>

                        <div class="valores">
                            <div class="cell valor" *ngFor="let valor of [1]">
                                <div class="area">
                                    <input type="text" mask="0" disabled autocomplete="off" tabindex="-1">
                                </div>
                            </div>
                            <div class="cell valor" *ngFor="let valor of config1.resultado; let index = index">
                                <div class="area">
                                    <input type="text" mask="0" readonly [(ngModel)]="resultado1Final[index]"
                                        autocomplete="off" tabindex="-1">
                                </div>
                            </div>
                        </div>

                        <div class="valores">
                            <div class="cell valor" *ngFor="let valor of config.valores[2]; let index = index">
                                <div class="area">
                                    <input type="text" mask="0" readonly [(ngModel)]="resultado2Final[index]"
                                        autocomplete="off" tabindex="-1">
                                    <div class="face" *ngIf="index == 4">
                                        <img src="./assets/templates/face.svg" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="resultado">
                            <div class="cell" *ngFor="let valor of config.resultado; let index = index">
                                <div class="area">
                                    <input type="text" mask="0" readonly [(ngModel)]="resultadoFinal[index]"
                                        autocomplete="off" tabindex="-1">
                                </div>
                            </div>
                        </div>
                        <div class="separador separador-0">
                            <div class="cell extra-i"></div>
                            <div class="cell centena" *ngFor="let valor of config.resultado; let index = index"></div>
                            <div class="cell extra-d"></div>
                        </div>

                        <div class="separador separador-2">
                            <div class="cell extra-i"></div>
                            <div class="cell centena" *ngFor="let valor of config.resultado; let index = index"></div>
                            <div class="cell extra-d"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer btns w-100 d-flex justify-content-between">
        <div class="like slide-up" *ngIf="likeManager.like"><img src="./assets/templates/smile.svg" alt=""></div>
        <button class="btn btn-reiniciar mt-auto" tabindex="-1" (click)="restart()">Reiniciar</button>
        <div class="d-flex flex-column justify-content-center align-items-center">
            <div class="title">Entrenamiento</div>
            <button class="btn btn-entrenamiento mt-auto" *ngIf="entrenamiento" (click)="toggleEntrenamiento()"
                tabindex="-1">Activado</button>
            <button class="btn btn-desentrenamiento mt-auto" *ngIf="!entrenamiento" (click)="toggleEntrenamiento()"
                tabindex="-1">Desactivado</button>
        </div>
        <button class="btn btn-listo mt-auto" (click)="listo()" *ngIf="isReady()">Listo</button>
        <button class="btn btn-listo disabled mt-auto" *ngIf="!isReady()">Listo</button>
    </div>
</div>