import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormatterService } from 'src/app/utils/formatter/formatter.service';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss']
})
export class QuestionComponent implements OnInit {

  @Input() mensaje = '¿Está seguro?'

  constructor(
    public activeModal: NgbActiveModal,
    public formatter: FormatterService
  ) { }

  ngOnInit(): void {
  }

}
