<div class="modal-content ">
    <div class="modal-header">
        <h4 class="modal-title">Ventana de Correción</h4>
        <button type="button" class="btn-close btn-close-white" aria-label="Close"
            (click)="activeModal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body bg-white">
        <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
            <li [ngbNavItem]="1">
                <a ngbNavLink>Respuesta Correcta</a>
                <ng-template ngbNavContent>
                    <div class="work-area d-flex flex-row justify-content-between p-3">
                        <div class="steps d-flex gap-2 flex-column problema" style="transform: scale(1) !important;">
                            <div class="step d-flex" *ngFor="let thisStep of config; let index = index">
                                <div class="d-flex" *ngIf="index < step">
                                    <div class="num1">{{invertido ? problema.valor1 : index+1}}</div>
                                    <i><img style="height: 30px; width: 30px;" src="./assets/templates/dot.svg"
                                            alt=""></i>
                                    <div class="num2">{{invertido ? index+1 : problema.valor1}}</div>
                                    <div class="mx-2"> = </div>
                                    <input type="text" readonly [value]="steps[index]">
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-evenly p-3 gap-3 align-items-start">
                            <div class="grid d-flex flex-column">
                                <div class="grid-row d-flex flex-row">
                                    <div class="grid-cell white">
                                        <div class="cell-btn">
                                        </div>
                                    </div>
                                    <div class="grid-cell white" *ngFor="let column of [1,2,3,4,5,6,7,8,9,10]">
                                        <div class="cell-btn">
                                            <div class="half start v-line" *ngIf="cols[step-1] && column == 1"></div>
                                            <div class="cell-data cell-r"
                                                [ngClass]="{'d-none': false, 'd-block': (((cols[step-1])/2) <= column && ((cols[step-1]+2)/2) > column) || (cols[step-1] != 0 && cols[step-1] == 10 && column == 5)}">
                                                {{(cols[step-1]) == 1 ? 'Uno' : numberText[(cols[step-1])]}}
                                            </div>
                                            <div class="h-line"
                                                *ngIf="cols[step-1] >= column || (cols[step-1] == 10 && steps[step-1])">
                                            </div>
                                            <div class="half middle v-line" *ngIf="false"></div>
                                            <div class="half end v-line"
                                                *ngIf="cols[step-1] == column || (cols[step-1] == 10 && column == 10)">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="grid-row d-flex flex-row" *ngFor="let row of [0,1,2,3,4,5,6,7,8,9]">
                                    <div class="grid-cell white">
                                        <div class="cell-btn">
                                            <div class="half start h-line" *ngIf="(rows[step-1]+1) >= row && row == 0">
                                            </div>
                                            <div class="cell-data cell-c bg-white"
                                                [ngClass]="{'d-none': false, 'd-block': ((row+1)) >= ((rows[step-1]+1)/2) && (row < ((rows[step-1]+1)/2))}">
                                                {{numberText[ceil((rows[step-1]+1))]}} {{ceil(rows[step-1]) == 0 ? 'vez'
                                                : 'veces'}}
                                            </div>
                                            <div class="v-line" *ngIf="(rows[step-1]+1) > row"></div>
                                            <div class="half middle h-line" *ngIf="false"></div>
                                            <div class="half end h-line"
                                                *ngIf="((row+1)) >= (rows[step-1]+1) && (row < (rows[step-1]+1))"></div>
                                        </div>
                                    </div>
                                    <div class="grid-cell" *ngFor="let cell of [1,2,3,4,5,6,7,8,9,10]">
                                        <div class="cell-btn" [ngClass]="getClass(row, cell)">
                                            <div class="cell-data">
                                                {{(row+1) * cell}}
                                            </div>
                                            <div class="cell-popup"
                                                [ngClass]="{'d-flex': row == rows[step-1] && cell == cols[step-1], 'd-none': !(row == rows[step-1] && cell == cols[step-1])}">
                                                {{(row+1) * cell}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </li>
            <li [ngbNavItem]="2">
                <a ngbNavLink>Tu Respuesta</a>
                <ng-template ngbNavContent>
                    <div class="work-area d-flex flex-row justify-content-between p-3">
                        <div class="steps d-flex gap-2 flex-column problema" style="transform: scale(1) !important;">
                            <div class="step d-flex" *ngFor="let thisStep of config; let index = index">
                                <div class="d-flex" *ngIf="index < step">
                                    <div class="num1">{{invertido ? problema.valor1 : index+1}}</div>
                                    <i><img style="height: 30px; width: 30px;" src="./assets/templates/dot.svg"
                                            alt=""></i>
                                    <div class="num2">{{invertido ? index+1 : problema.valor1}}</div>
                                    <div class="mx-2"> = </div>
                                    <input type="text" readonly [value]="misSteps[index]">
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-evenly p-3 gap-3 align-items-start">
                            <div class="grid d-flex flex-column">
                                <div class="grid-row d-flex flex-row">
                                    <div class="grid-cell white">
                                        <div class="cell-btn">
                                        </div>
                                    </div>
                                    <div class="grid-cell white" *ngFor="let column of [1,2,3,4,5,6,7,8,9,10]">
                                        <div class="cell-btn">
                                            <div class="half start v-line" *ngIf="misCols[step-1] && column == 1"></div>
                                            <div class="cell-data cell-r"
                                                [ngClass]="{'d-none': false, 'd-block': (((misCols[step-1])/2) <= column && ((misCols[step-1]+2)/2) > column) || (misCols[step-1] != 0 && misCols[step-1] == 10 && column == 5)}">
                                                {{(misCols[step-1]) == 1 ? 'Uno' : numberText[(misCols[step-1])]}}
                                            </div>
                                            <div class="h-line"
                                                *ngIf="misCols[step-1] >= column || (misCols[step-1] == 10 && steps[step-1])">
                                            </div>
                                            <div class="half middle v-line" *ngIf="false"></div>
                                            <div class="half end v-line"
                                                *ngIf="misCols[step-1] == column || (misCols[step-1] == 10 && column == 10)">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="grid-row d-flex flex-row" *ngFor="let row of [0,1,2,3,4,5,6,7,8,9]">
                                    <div class="grid-cell white">
                                        <div class="cell-btn">
                                            <div class="half start h-line"
                                                *ngIf="(misRows[step-1]+1) >= row && row == 0">
                                            </div>
                                            <div class="cell-data cell-c bg-white"
                                                [ngClass]="{'d-none': false, 'd-block': ((row+1)) >= ((misRows[step-1]+1)/2) && (row < ((misRows[step-1]+1)/2))}">
                                                {{numberText[ceil((misRows[step-1]+1))]}} {{ceil(misRows[step-1]) == 0 ?
                                                'vez' : 'veces'}}
                                            </div>
                                            <div class="v-line" *ngIf="(misRows[step-1]+1) > row"></div>
                                            <div class="half middle h-line" *ngIf="false"></div>
                                            <div class="half end h-line"
                                                *ngIf="((row+1)) >= (misRows[step-1]+1) && (row < (misRows[step-1]+1))">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="grid-cell" *ngFor="let cell of [1,2,3,4,5,6,7,8,9,10]">
                                        <div class="cell-btn" [ngClass]="getMyClass(row, cell)">
                                            <div class="cell-data">
                                                {{(row+1) * cell}}
                                            </div>
                                            <div class="cell-popup"
                                                [ngClass]="{'d-flex': row == misRows[step-1] && cell == misCols[step-1], 'd-none': !(row == misRows[step-1] && cell == misCols[step-1])}">
                                                {{(row+1) * cell}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="p-3"></div>
    </div>
    <div class="modal-footer btns bg-white">
        <!-- <button type="button" class="btn btn-success" (click)="guardar()">Volver a intentar</button> -->
        <button type="button" class="btn btn-listo" (click)="activeModal.dismiss('Cancel click')">Revisado</button>
    </div>
</div>