import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService, LoadingService } from 'ngx-kuv-tools';
import { LevelManagerService } from './services/level-manager.service';
import { ResultService } from './services/result.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  routes: any[] = [];

  constructor(
    private loading: LoadingService,
    private alerts: AlertService,
    private modalService: NgbModal,
    public levelManager: LevelManagerService,
    private resultService: ResultService
  ) { }

  ngOnInit() {
    let nivel: string = this.resultService.getVar('n');
    if (!nivel) nivel = this.resultService.getVar('nivel');
    if(nivel != '') this.levelManager.goTo(parseInt(nivel));
    else this.levelManager.goTo(1);
  }
}
