<div class="modal-header justify-content-end">
    <button type="button" class="btn-close btn-expand btn-close-white" aria-label="Close" (click)="toggleFullscreen()">
        <svg style="margin-top: -15px;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
            class="bi bi-arrows-fullscreen" viewBox="0 0 16 16">
            <path fill-rule="evenodd"
                d="M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707m4.344 0a.5.5 0 0 1 .707 0l4.096 4.096V11.5a.5.5 0 1 1 1 0v3.975a.5.5 0 0 1-.5.5H11.5a.5.5 0 0 1 0-1h2.768l-4.096-4.096a.5.5 0 0 1 0-.707m0-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707m-4.344 0a.5.5 0 0 1-.707 0L1.025 1.732V4.5a.5.5 0 0 1-1 0V.525a.5.5 0 0 1 .5-.5H4.5a.5.5 0 0 1 0 1H1.732l4.096 4.096a.5.5 0 0 1 0 .707" />
        </svg>
    </button>
    <button type="button" class="btn-close btn-close-white" aria-label="Close"
        (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body bg-white h-100">
    <div class="btn-group w-100" role="group" aria-label="Basic example">
        <button type="button" class="btn"
            [ngClass]="{'active btn-orange': tablaInteractiva, 'btn-outline-orange': !tablaInteractiva}"
            (click)="toggleTabla(true)">Tabla pictórica</button>
        <button type="button" class="btn"
            [ngClass]="{'active btn-orange': !tablaInteractiva, 'btn-outline-orange': tablaInteractiva}"
            (click)="toggleTabla(false)">Modo simbólico</button>
    </div>
    <div class="contenedor-principal w-100 d-flex flex-column justify-content-start p-2 p-sm-3">
        <div class="d-flex flex-column align-items-center" *ngIf="!mostrarTabla">
            <h3>¿QUÉ TABLA DESEA UTILIZAR?</h3>
            <div class="d-flex flex-column gap-2">
                <div class="d-flex gap-2">
                    <div class="btn btn-listo" (click)="setTabla(2)">Tabla del 2</div>
                    <div class="btn btn-listo" (click)="setTabla(3)">Tabla del 3</div>
                    <div class="btn btn-listo" (click)="setTabla(4)">Tabla del 4</div>
                </div>
                <div class="d-flex gap-2">
                    <div class="btn btn-listo" (click)="setTabla(5)">Tabla del 5</div>
                    <div class="btn btn-listo" (click)="setTabla(6)">Tabla del 6</div>
                    <div class="btn btn-listo" (click)="setTabla(7)">Tabla del 7</div>
                </div>
                <div class="d-flex gap-2">
                    <div class="btn btn-listo" (click)="setTabla(8)">Tabla del 8</div>
                    <div class="btn btn-listo" (click)="setTabla(9)">Tabla del 9</div>
                </div>
            </div>
        </div>
        <div class="h-100 w-100" [ngClass]="{'flex-grow min-h-100': nivel % 2 == 0}" *ngIf="mostrarTabla">
            <div class="work-area w-100 d-flex flex-column justify-content-evenly p-3 gap-3">
                <div class="d-flex flex-column flex-md-row w-100 justify-content-between p-3 gap-5 align-items-start">
                    <div class="steps d-flex gap-2 flex-column problema w-100" style="transform: scale(1);">
                        <div class="options d-flex flex-column flex-md-row justify-content-start">
                            <button class="btn btn-informacion" (click)="mostrarInformacion()"><img style="width: 100px; height: 100px;"
                                    src="./assets/templates/info.svg" alt="Información"></button>
                                    <div class="problema d-flex w-100 justify-content-center align-items-center" style="transform: scale(1.2);">
                                        <div id="invertir" style="white-space: nowrap;">
                                            <div class="d-flex justify-content-start align-items-center" style="white-space: nowrap;">
                                                <input type="checkbox" class="custom-control-input" id="invertur" [(ngModel)]="noInvertido">
                                                <label class="custom-control-label" for="invertur">Conmutar</label>
                                            </div>
                                        </div>
                                    </div>
                        </div>
                        <div class="d-flex justify-content-center">
                            <div class="d-flex flex-column gap-2">
                                <div class="step d-flex ms-4"
                                    *ngFor="let thisStep of config; let index = index" style="transform: scale(1.2);">
                                    <div class="d-flex" *ngIf="index < problema.valor2"
                                        [ngClass]="{'colored colored-1': step == index && step%2==0, 'colored colored-2': step == index && step%2==1}">
                                        <div class="d-flex">
                                            <div class="num1">{{invertido ? problema.valor1 : index+1}}</div>
                                            <i><img style="height: 30px; width: 30px;" src="./assets/templates/dot.svg" alt=""></i>
                                            <div class="num2">{{invertido ? index+1 : problema.valor1}}</div>
                                            <div class="mx-2"> = </div>
                                            <input type="text" readonly [value]="steps[index]"
                                                *ngIf="step != index || nivel % 2 == 1">
                                            <input type="text" autocomplete="off" mask="separator.0" thousandSeparator="."
                                                [(ngModel)]="steps[index]" *ngIf="step == index && nivel % 2 == 0">
                                        </div>
                                        <button class="btn btn-reiniciar" style="margin-left: 1rem;"
                                            *ngIf="index+2 == steps.length && index+2 > 0"
                                            (click)="deshacer()">Deshacer</button>
                                        <div *ngIf="index+2 != steps.length || index+2 <= 0"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="grid d-flex flex-column" *ngIf="nivel % 2 == 1">
                        <div class="grid-row d-flex flex-row">
                            <div class="grid-cell white">
                                <div class="cell-btn">
                                </div>
                            </div>
                            <div class="grid-cell white" *ngFor="let column of [1,2,3,4,5,6,7,8,9,10]">
                                <div class="cell-btn">
                                    <div class="half start v-line" *ngIf="hover && column == 1"></div>
                                    <div class="cell-data cell-r"
                                        [ngClass]="{'d-none': false, 'd-block': (((hover %10)/2) <= column && ((hover %10+2)/2) > column) || (hover != 0 && hover % 10 == 0 && column == 5), 'step-aside': (hover%10) % 2 == 0}">
                                        <!-- {{(invertido ? steps.length : problema.valor1) == 1 ? 'Uno' : numberText[invertido ? steps.length : problema.valor1]}} -->
                                        {{(hover %10) == 1 ? 'Uno' : numberText[(hover %10)]}}
                                    </div>
                                    <div class="h-line" *ngIf="hover%10 >= column || (hover % 10 == 0 && hover)"></div>
                                    <div class="half middle v-line top"
                                        *ngIf="(hover%10) % 2 == 1 && ((((hover %10)/2) <= column && ((hover %10+2)/2) > column) || (hover != 0 && hover % 10 == 0 && column == 5))">
                                    </div>
                                    <div class="half end v-line top"
                                        *ngIf="((hover%10) % 2 == 0) && (((hover %10)/2) <= column && ((hover %10+2)/2) > column) || (hover != 0 && hover % 10 == 0 && column == 5)">
                                    </div>
                                    <div class="half end v-line"
                                        *ngIf="hover %10 == column || (hover % 10 == 0 && hover && column == 10)"></div>
                                </div>
                            </div>
                        </div>
                        <div class="grid-row d-flex flex-row" style="touch-action: none;"
                            *ngFor="let row of [0,1,2,3,4,5,6,7,8,9]">
                            <div class="grid-cell white">
                                <div class="cell-btn">
                                    <div class="half start h-line" *ngIf="hover > row * 10 && row == 0"></div>
                                    <div class="cell-data cell-c bg-white"
                                        [ngClass]="{'d-none': false, 'd-block': ((row+1) * 10) >= (hover/2) && (row * 10 < (hover/2)), 'step-down': ceil(hover / 10) % 2 == 0}">
                                        <!-- {{numberText[invertido ? problema.valor1 : steps.length]}} {{(invertido ? problema.valor1 : steps.length) == 1 ? 'vez' : 'veces'}} -->
                                        {{numberText[ceil(hover/10)]}} {{ceil(hover/10) == 1 ? 'vez' : 'veces'}}
                                    </div>
                                    <div class="v-line" *ngIf="hover > row * 10"></div>
                                    <div class="half middle h-line side"
                                        *ngIf="((row+1) * 10) >= (hover/2) && (row * 10 < (hover/2)) && ceil(hover / 10) % 2 == 1">
                                    </div>
                                    <div class="half end h-line side"
                                        *ngIf="((row+1) * 10) >= (hover/2) && (row * 10 < (hover/2)) && ceil(hover / 10) % 2 == 0">
                                    </div>
                                    <div class="half end h-line" *ngIf="((row+1) * 10) >= hover && (row * 10 < hover)">
                                    </div>
                                </div>
                            </div>
                            <div class="grid-cell" *ngFor="let cell of [1,2,3,4,5,6,7,8,9,10]"
                                (mouseover)="markHover(row, cell)" (mouseleave)="unmarkHover(row, cell)"
                                (touchmove)="markMove($event, row, cell)" (touchend)="finishHover($event, row, cell)"
                                (touchstart)="markHover(row, cell)" [attr.row]="row" [attr.cell]="cell">
                                <div class="cell-btn" [ngClass]="getClass(row, cell)"
                                    (click)="select(row*10 + cell, row, cell)" [attr.row]="row" [attr.cell]="cell">
                                    <div class="cell-data"></div>
                                    <div class="cell-popup">
                                        {{(row+1) * cell}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer bg-white">
    <div class="btns w-100 d-flex justify-content-between">
        <div class="like slide-up" *ngIf="likeManager.like"><img src="./assets/templates/smile.svg" alt="">
        </div>
        <button class="btn btn-reiniciar mt-auto d-none" tabindex="-1" (click)="restart()">Reiniciar</button>
        <div class="d-flex flex-column justify-content-center align-items-center">
        </div>
        <button class="btn btn-listo mt-auto" [ngClass]="{'d-none': tablaInteractiva}" (click)="checkFinalAnswer()" *ngIf="mostrarTabla && isReady()">Listo</button>
        <button class="btn btn-listo disabled mt-auto" [ngClass]="{'d-none': tablaInteractiva}" *ngIf="mostrarTabla && !isReady()">Listo</button>
    </div>
</div>