import { Injectable } from '@angular/core';
import { ResultService } from './result.service';

@Injectable({
  providedIn: 'root'
})
export class LevelManagerService {

  show: boolean[] = new Array(12).fill(false)

  constructor(
    private result: ResultService
  ) { }

  anyActive(){
    return this.show.reduce((t,e) => t || e, false);
  }

  showNextLevel(){
    let index = this.show.findIndex(e => e);
    this.show[index] = false;
    this.show[index+1] = true;
    this.result.cleanResults();
  }

  goTo(level: number){
    this.show= new Array(11).fill(false);
    setTimeout(() => {
      this.show[level] = true;
      this.result.cleanResults();
    }, 100)
  }
}
