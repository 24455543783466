import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbDropdownModule, NgbModalModule, NgbModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxKuvToolsModule } from 'ngx-kuv-tools';
import { NgxKuvUtilsModule } from 'ngx-kuv-utils';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { HomeComponent } from './components/home/home.component';
import { Nivel1Component } from './components/niveles/nivel1/nivel1.component';
import { Nivel2Component } from './components/niveles/nivel2/nivel2.component';
import { Nivel3Component } from './components/niveles/nivel3/nivel3.component';
import { Nivel4Component } from './components/niveles/nivel4/nivel4.component';
import { Nivel5Component } from './components/niveles/nivel5/nivel5.component';
import { Nivel6Component } from './components/niveles/nivel6/nivel6.component';
import { ErrorComponent } from './components/error/error.component';
import { QuestionComponent } from './components/question/question.component';
import { ErrorResultadoComponent } from './components/error-resultado/error-resultado.component';
import { RingComponent } from './components/ring/ring.component';
import { Nivel25Component } from './components/niveles/nivel25/nivel25.component';
import { Nivel35Component } from './components/niveles/nivel35/nivel35.component';
import { Nivel45Component } from './components/niveles/nivel45/nivel45.component';
import { Nivel55Component } from './components/niveles/nivel55/nivel55.component';
import { Nivel65Component } from './components/niveles/nivel65/nivel65.component';
import { InstruccionComponent } from './components/instruccion/instruccion.component';
import { TablaSelectorComponent } from './components/tabla-selector/tabla-selector.component';
import { Error2Component } from './components/error2/error2.component';
import { ErrorResultado2Component } from './components/error-resultado2/error-resultado2.component';
import { RouterModule } from '@angular/router';
import { IframeComponent } from './components/iframe/iframe.component';

const maskConfig: Partial<IConfig> = {};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    Nivel1Component,
    Nivel2Component,
    Nivel3Component,
    Nivel4Component,
    Nivel5Component,
    Nivel6Component,
    ErrorComponent,
    QuestionComponent,
    ErrorResultadoComponent,
    RingComponent,
    Nivel25Component,
    Nivel35Component,
    Nivel45Component,
    Nivel55Component,
    Nivel65Component,
    InstruccionComponent,
    TablaSelectorComponent,
    Error2Component,
    ErrorResultado2Component,
    IframeComponent
  ],
  imports: [
    BrowserModule,
    // AppRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgxKuvUtilsModule,
    NgbPopoverModule,
    NgbDropdownModule,
    HttpClientModule,
    NgbModalModule,
    NgxKuvToolsModule,
    NgSelectModule,
    NgxMaskModule.forRoot(maskConfig),
    NgSelectModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
